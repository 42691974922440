import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import React from "react";
import { Routes } from "../../../router/routes";
import { LoderSimmer } from "../../../components";
import { AlertContext } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
    borderRadius: theme.palette.borderRadius2,
    border: "1px solid #E4E8EE",
    padding: "16px 16px 12px 16px",
    marginBottom: "16px",
    cursor: "pointer",
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    marginBottom: "6px",
  },
  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  img: {
    width: "68px",
    height: "68px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    objectFit: "contain",
  },
  avatar: {
    width: "300px",
    height: "60px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    objectFit: "contain",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold
  },
  img2: {
    width: "100%",
    height: "60px",
    borderRadius: "4px",
    objectFit: "contain",
  },
  img3: {
    width: "300px",
    height: "60px",
    borderRadius: "4px",
    objectFit: "cover",
  },
}));
const ExploreProperty = (props) => {
  const history = useHistory();

  const goTo = ({ route, url }) => {
    switch (route) {
      case Routes["#"]:
        history.push({
          pathname: route,
          state: { url }
        });
        break;

      default:
        history.push(route)
        break;
    }
  }

  const alert = React.useContext(AlertContext);
  const client = JSON.parse(localStorage.getItem(LocalStorageKeys.client));
  const [property, setProperty] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const verifyClient = () => {
    setLoading(true)
    const payload = {
      client_id: client?.id
    };
    NetworkCall(
      `${config.public_listing_api_url}/verify/client`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setProperty(response?.data)
        setLoading(false)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong",
        });
        setLoading(false)
      });
  };

  React.useEffect(() => {
    verifyClient();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {
        loading
          ?
          <LoderSimmer card count={3} size={40} />
          :
          <>
            {property?.response?.custom_name?.length > 0 && props?.controlList?.privateListings &&
              <CardsNew title={property?.response?.custom_name} subTitle="You can find all their properties here"
                open={() => { goTo({ route: Routes["#"], url: property?.response?.custom_url }) }}
                image={property?.response?.client_logo} avatar={property?.response?.client_logo?.length > 0 ? false : true} />
            }
            {props?.controlList?.amenityGoTo &&
              <CardsNew
                open={() => { goTo({ route: Routes["#"], url: property?.response?.amenity_url }) }}
                image={"/images/AmenitiesGOTO.svg"} original={true} />
            }
            {props?.controlList?.realtyGoTo &&
              <CardsNew
                open={() => { goTo({ route: Routes["#"], url: property?.response?.realty_url }) }}
                image={"/images/RealtyGOTO.svg"} original={true} />
            }

          </>
      }
    </Box>
  );
};
// const Cards = ({ title = "", subTitle = "", open, image = "image.png", avatar = false }) => {
//   const classes = useStyles();

//   return (
//     <Box className={classes.cardContainer} onClick={open}>
//       <Box>
//         {avatar ?
//           <Avatar {...stringAvatar(title ?? "A")} className={classes.img} /> :
//           <img className={classes.img} src={`/images/${image}`} alt="" />}
//       </Box>
//       <Box flexGrow={1}>
//         <Typography className={classes.title}>{title}</Typography>
//         <Typography className={classes.subTitle}>
//           {subTitle}
//         </Typography>
//       </Box>
//       <Box>
//         <KeyboardArrowRight />
//       </Box>
//     </Box>
//   );
// };
const CardsNew = ({ title = "", subTitle = "", open, image = "image.png", avatar = false, original = false }) => {
  const classes = useStyles();
  const stringAvatarNew = (name) => {
    let capName = name ? name.toUpperCase() : undefined
    return {
      sx: {
        bgcolor: "#ffffff",
      },
      children: capName,
    };
  }
  return (
    <Box className={classes.cardContainer} onClick={open}>
      <Box>
        {avatar ?
          <>
            <Avatar {...stringAvatarNew(title ?? "A")} className={classes.avatar} />
          </>
          :
          <img className={original ? classes.img2 : classes.img3} src={`${image}`} alt="" />}
      </Box>


      <Box>
        <KeyboardArrowRight />
      </Box>
    </Box>
  );
};
export default ExploreProperty;
