import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import moment from "moment-timezone";
// import ShowMoreText from "react-show-more-text";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { DatePickerNew, DatePickerTimeNew, DialogDrawer, TextBox } from "../../components";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GETSINGLEGENERALREQUESTBYGENERALREQUESTNO,
  GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, concat_string, generateImageUrl, img_size } from "../../utils";
import { useStyles } from './style';
// import GreenTick from "../../assets/greenTick";
import ClearIcon from '@mui/icons-material/Clear';
import BigGreenTick from "../../assets/bigGreenTick";
import RedCross from "../../assets/redCross";
// import InspectionReport from "../../assets/inspectionReport";
import { KeyboardArrowRight } from "@mui/icons-material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from "date-fns";
import ShowMoreText from "react-show-more-text";
import Slider from "react-slick";
import { AcknowledgeIcon } from "../../assets/acknowledgeIcon";
import { ApprovalStartIcon } from "../../assets/approvalStart";
import { BOMIcon } from "../../assets/bomIcon";
import { CompletionIcon } from "../../assets/completionIcon";
import { FirstResponseIcon } from "../../assets/firstResponse";
import { InfoIcon } from "../../assets/infoIcon";
import { InvoiceIcon } from "../../assets/invoiceIcon";
import { JobIcon } from "../../assets/jobIcon";
import { NonAssetIcon } from "../../assets/nonAssetIcon";
import { RescheduleTextIcon } from "../../assets/reScheduleIcon";
import RecordActualExpense from "../../assets/recordActualExpense";
import RecordExpenseIcon from "../../assets/recordExpenseIcon";
import Resend from "../../assets/resend";
import { RescheduleIcon } from "../../assets/resscheduleIco";
import { UpfrontChargesIcon } from "../../assets/upfrontChargesIcon";
import { BackendRoutes, Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import MicIcon from '@mui/icons-material/Mic';

const InitialState = {
  remarks: "",
  error: {
    remarks: "",
  },
};
const RequestDetails = (props) => {
  const match = useMediaQuery('(min-width:602px)')
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: match ? 3 : 2.5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  // const graphql_data_key = {
  //   General: "general_request",
  //   Maintenance: "maintenance_request",
  // };
  // const graphql_status_key = {
  //   General: "general_status",
  //   Maintenance: "maintenance_status",
  // };
  const language = localStorage.getItem("i18nextLng")
  const currency = localStorage?.getItem(LocalStorageKeys?.clientCurrency) ? JSON?.parse(localStorage?.getItem(LocalStorageKeys?.clientCurrency)) : "";
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const history = useHistory();
  const search = useLocation().search;
  const size = useWindowDimensions()
  const alert = React.useContext(AlertContext);
  const jwt = new URLSearchParams(search).get("req_data");
  const token = jwt && jwt_decode(jwt)
  const requestId = jwt ? token?.id : new URLSearchParams(search).get("requestId");
  const requestNo = jwt ? token?.id : new URLSearchParams(search).get("requestNo");
  const initialStatus = jwt ? token?.status : new URLSearchParams(search).get("status");
  const requestType = jwt ? token?.type : new URLSearchParams(search).get("requestType");
  const jobCount = jwt ? token?.job_count : new URLSearchParams(search).get("job_count");
  const clientCurrency = JSON.parse(localStorage.getItem(LocalStorageKeys.clientCurrency))
  const [status, setStatus] = React.useState(initialStatus);
  const [request, setRequest] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false)
  const [accept, setAccept] = React.useState("");
  const [acceptDrawer, setAcceptDrawer] = React.useState(false);
  const [rejectDrawer, setRejectDrawer] = React.useState(false);
  const [actualExpense, setActualExpense] = React.useState("")
  const [recordExpenseDrawer, setRecordExpenseDrawer] = React.useState(false)
  const [serviceLevelData, setServiceLevelData] = React.useState([])
  const [cancel, setCancel] = React.useState(false);
  const [canceldata, setCancelData] = React.useState({ ...InitialState });
  const [trackOpen, setTrackOpen] = React.useState(false)
  const [reason, setReason] = React.useState("")
  const [jobData, setJobData] = React.useState([])
  const [isVisit, setIsVisit] = React.useState({
    bool: false,
    type: "",
    data: { visitdate: new Date() },
    rescheduleDateTime: new Date()
  })
  const [rescheduleData, setRescheduleData] = useState({ visitDate: new Date() })
  const [itemDetails, setItemDetails] = React.useState({
    list: [],
    costing_status: "Pending"
  })
  const [view, setView] = React.useState({
    bool: false,
    data: 0
  });
  const [slotsData, setSlotsData] = React.useState([])
  const TimeIn24HrFormat = [null, null, { start: "08", end: "10" }, { start: "10", end: "12" }, { start: "12", end: "14" }, { start: "14", end: "16" }, { start: "16", end: "18" }, { start: "18", end: "20" }]
  const [showCharges, setShowCharges] = React.useState(false)
  const [imageViewer, setImageViewer] = React.useState(false);
  const [invoice_state, set_invoice_state] = useState({
    total_invoice_count: 0,
    total_invoice_amount: 0,
  });
  const updateState = (key, value) => {
    let error = canceldata.error;
    error[key] = "";
    setCancelData({ ...canceldata, [key]: value, error });
  };

  const getTitle = (requestType, request) => {
    let title = ""
    if (requestType === "General") {
      title = request?.general_request?.[0]?.general_request_no
    } else {
      title = request?.maintenance_request?.[0]?.maintenance_request_no
    }
    return title;
  }
  const getSlotMaster = (offset = 0, limit = 10, search = "") => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search
    }
    NetworkCall(
      `${config.api_url}/time_slot_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((x, i) => {
          return {
            ...x,
            time: TimeIn24HrFormat[i]
          }
        })
        setSlotsData(result)
      })
      .catch((error) => {
        console.log(error)
      });
  };
  React.useEffect(() => {

    getRequestDetails()
    getSlotMaster()
    getServiceLevelAgreementDetails()
    getMaintenanceJobDetails()
    if (requestType === "Maintenance") {
      getCostingDetails()
      getInvoiceDetails();
    }

    // eslint-disable-next-line
  }, [status]);

  const getRequestDetails = () => {
    const payload = {
      query:
        requestType === "General"
          ? GETSINGLEGENERALREQUESTBYGENERALREQUESTNO
          : GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO,
      variables: {
        generalRequestNo: requestType === "General" ? requestNo : undefined,
        maintenanceRequestNo: requestType === "Maintenance" ? requestNo : undefined,
        referenceId: requestId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let temp_request = response?.data?.data
        setRequest({
          ...temp_request,
          allow_cancel_request: (initialStatus === "Closed" || initialStatus === "Cancelled" || initialStatus === "Assigned") || jobCount > 0 ? false : true,
        })
        setLoading(false);
        handleTopBarState({
          text: getTitle(requestType, temp_request)
        })
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }
  const getServiceLevelAgreementDetails = () => {
    const payload = {
      "request_id": requestNo,
      "type": requestType,
    }

    NetworkCall(
      `${config.api_url}/request/service_level_agreement`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setServiceLevelData(response?.data?.data)
      // console.log(response?.data?.data?.[0]?.costing?.length>0)
      // setCostingData(response?.data?.data?.[0]?.costing?.length > 0 ? addId(response?.data?.data?.[0]?.costing) : [])

    }).catch((err) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })
  }
  //get  invoices
  const getInvoiceDetails = (offset = 0, limit = 10, search = "") => {
    const payload = {
      id: requestNo,
      offset, limit, search
    }

    NetworkCall(
      `${config.api_url}${BackendRoutes?.invoice_maintenance_invoice}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      let temp_invoice_state = {
        total_invoice_count: response?.data?.count?.[0]?.count ?? 0,
        total_invoice_amount: response?.data?.total_invoice_amount ?? 0,
      }

      set_invoice_state(temp_invoice_state);
    }).catch((err) => {
      console.log(err)
    })

  }
  const getMaintenanceJobDetails = () => {
    const payload = {
      "maintenance_request_id": requestNo,
    }

    NetworkCall(
      `${config.api_url}/request/get_maintenance_job_visits`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setJobData(response?.data?.data)
    }).catch((err) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })
  }
  // RECORD ACTUAL EXPENSE
  const updateActualExpense = () => {
    const payload = {
      request_id: requestType === "Maintenance" ? request?.maintenance_request?.[0]?.id : request?.general_request?.[0]?.id,
      type: requestType,
      actual_expense: parseFloat(actualExpense)
    }
    NetworkCall(
      `${config.api_url}/request_costing/update_actual_expense`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      getRequestDetails()
      closeExpenseDrawer()
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: (request?.maintenance_request?.[0]?.actual_expense === null ? t("Added Successfully") : t("Updated Successfully")),
      });
    }).catch((err) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    })
  }

  const openExpenseDrawer = (actualexpense) => {
    setActualExpense(actualexpense)
    setRecordExpenseDrawer(true)
  }

  const closeExpenseDrawer = () => {
    setActualExpense("")
    setRecordExpenseDrawer(false)
  }

  const isIamValideTostep = () => {
    let isValid = true;
    let error = canceldata.error;
    //Checking Pass Type
    if (canceldata.remarks.length === 0) {
      isValid = false;
      error.remarks = t("RemarksisRequired");
    }

    setCancel({ ...canceldata, error });
    return isValid;
  };
  const cancelPass = () => {
    if (isIamValideTostep()) {
      const payload = {
        req_type: requestType === "General" ? 1 : 2,
        remarks: canceldata?.remarks,
        request_id: requestId,
        status: "Cancelled"
      }

      NetworkCall(
        `${config?.api_url}${BackendRoutes?.request_close_request}`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((response) => {
        history.replace(
          window.location.pathname +
          "?requestId=" +
          requestId +
          "&requestNo=" +
          requestNo +
          "&requestType=" +
          requestType +
          "&status=Cancelled"
        );
        setStatus("Cancelled");
        setCancel(false);
      })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went wrong please try again"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };
  const acceptCostingRequest = (status) => {

    const payload = {
      "maintenance_request_id": requestType === "Maintenance" ? requestNo : undefined,
      "general_request_id": requestType === "General" ? requestNo : undefined,
      "costing_status": status,
      "description": reason
    }

    NetworkCall(
      `${config.api_url}/request_costing/visting_charge/update_status`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      if (status === "Approved") {
        setAcceptDrawer(true)
        setAccept(true)
        getRequestDetails()
        setShowCharges(false)
      }
      else {
        getRequestDetails()
        setRejectDrawer(false)
        setShowCharges(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Costing Request Rejected Successfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }

    }).catch((err) => {

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Something Went Wrong"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })
  }


  const renderCancelForm = () => {
    return (
      <div style={{ padding: "12px" }}>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("CancelGateRequest")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setCancel(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box height="12px" />
        <TextBox
          color
          label={t("Remarks")}
          placeholder={t("EnterRemarks")}
          value={canceldata.remarks}
          multiline
          onChange={(value) => updateState("remarks", value.target.value)}
          isError={canceldata?.error?.remarks?.length > 0}
          errorMessage={canceldata?.error?.remarks}
        />
        <Box height="12px" />
        <Button
          fullWidth
          className={classes.submitbtn}
          onClick={cancelPass}
          variant="contained"
        >
          {t("Submit")}
        </Button>
      </div>
    );
  };
  const track = requestType === "General" ? request?.general_request?.[0]?.general_status : request?.maintenance_request?.[0]?.maintenance_status
  const viewDialog = (close, value) => {
    if (close) {
      setView({ ...view, bool: !view?.bool })
    }
    else {
      setView({ data: value, bool: !view?.bool })
    }
  }
  //get Costing Item details
  const getCostingDetails = (data) => {
    const payload = {
      "request_id": requestNo,
      "type": requestType,
      "bom_type": "Projected"
    }

    NetworkCall(
      `${config.api_url}/request_costing/get_costing`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setItemDetails({ list: response?.data?.data?.[0]?.costing?.[0], costing_status: response?.data?.data?.[0]?.costing_status })

    }).catch((err) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })
  }

  const handleBomDetails = () => {
    history.push({
      pathname: Routes?.BOMDetails,
      state: {
        requestId: requestId,
        requestNo: requestNo,
        requestType: requestType,
        itemDetails: itemDetails
      }
    })
  }
  const handleReschedule = (type) => {
    if (type === "Rescheduled") {
      if (rescheduleData?.selectedSlot?.label?.length > 0) {
        handleJobSchedule(type)
      }
      else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Choose Time Slot"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    }
    else {
      handleJobSchedule(type)
    }
  }
  const handleJobSchedule = (type) => {
    const payload = {
      "id": isVisit?.data?.id,
      "maintenance_request_id": requestNo,
      "job_id": isVisit?.data?.job_id,
      "job_no": isVisit?.data?.job_no,
      "rescheduled_datetime": type === "Rescheduled" ? rescheduleData?.visitDate : null,
      "slot_id": type === "Rescheduled" ? rescheduleData?.selectedSlot?.value : null,
      "slot_name": type === "Rescheduled" ? rescheduleData?.selectedSlot?.label : null,
      "status": type
    }
    NetworkCall(
      `${config.api_url}/request/maintenance_job_visit_approval`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setIsVisit({ ...isVisit, bool: false, data: {}, type: "" })
      getMaintenanceJobDetails()
      getRequestDetails()
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: type === "Rescheduled" ? t("Rescheduled Successfully") : t("Approved Successfully"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }).catch((err) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    })
  }
  const handleInvoiceNudge = () => {
    history.push({
      pathname: Routes?.pay_request_invoice_list,
      state: {
        id: requestId,
      },
    });
  }
  const handleChangeSlot = (val) => {
    if (val?.time !== null && moment(rescheduleData?.visitDate).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
      const currentTime = moment(new Date())
      let fromTime = new Date(currentTime).setHours(val?.time?.start, 0, 0, 0);
      let toTime = (new Date(currentTime)).setHours(val?.time?.end, 0, 0, 0);
      if ((fromTime <= currentTime && currentTime <= toTime) || fromTime >= currentTime) {
        setRescheduleData({ ...rescheduleData, selectedSlot: val })
      } else {
        setRescheduleData({ ...rescheduleData, selectedSlot: "" })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Choose time Slot greater than Current Time"),
        });
      }
    }
    else {
      setRescheduleData({ ...rescheduleData, selectedSlot: val })
    }
  }
  // const handlePayNow = (e) => {
  //   e?.stopPropagation();
  // }
  return (
    <div>
      <Container className={classes.root} sx={{ padding: "0px" }}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <div style={{ overflow: "auto", padding: "0px 16px", height: request?.allow_cancel_request ? size?.height - 120 : size?.height - 50 }}>
              {(request?.maintenance_request?.[0]?.visiting_charge_request?.length > 0 || request?.general_request?.[0]?.visiting_charge_request?.length > 0) &&
                <>
                  <Box height={'16px'} />
                  <Box className={classes.requestPendingBox} p={2} onClick={() => setShowCharges(true)}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <UpfrontChargesIcon />
                        <Box>
                          {requestType === "Maintenance" &&
                            <Typography className={classes.notesTitle}>{`${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""}`}</Typography>
                          }
                          {requestType === "General" &&
                            <Typography className={classes.notesTitle}>{`${request?.general_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.general_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""}`}</Typography>
                          }
                          <Typography className={classes.subText}>{t("Upfront Charges")}</Typography>
                        </Box>
                      </Stack>
                      {(request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Pending" || request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Pending") ?
                        <KeyboardArrowRight sx={{ color: "#98A0AC" }} />
                        :
                        <Typography className={classes.costingStatusText} sx={{ backgroundColor: (request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Rejected" || request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Rejected") === "Rejected" ? "#FF4B4B" : "#5AC782" }}>{requestType === "Maintenance" ? t(request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status) : t(request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status)}</Typography>
                      }
                    </Box>
                    {(request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Pending" || request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Pending") &&
                      <Box mt={1.5}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Button fullWidth variant="contained" className={classes.rejectbtn}>{t("Reject")}</Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button fullWidth variant="contained" className={classes.approvebtn}>{t("Approve")}</Button>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  </Box>
                </>
              }
              {itemDetails?.list?.items?.length > 0 && itemDetails?.costing_status !== null &&
                <>
                  <Box height={'16px'} />
                  <Box className={classes.requestPendingBox} p={2} onClick={() => handleBomDetails()}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <BOMIcon />
                        <Box>
                          {requestType === "Maintenance" &&
                            <Box display={"flex"}>
                              <Typography className={classes.notesTitle}>{`${itemDetails?.list?.symbol} ${itemDetails?.list?.total_amount_after_tax ?? 0}`}</Typography>
                              <Box ml={1}>
                                <Tooltip title={t("Note: Usage of Material and labor will be updated periodically. Upon completion of work, the Actual The cost of labour, materials, and Other Expenses will be shared.")}>
                                  <InfoIcon />
                                </Tooltip>
                              </Box>
                            </Box>
                          }
                          <Typography className={classes.subText}>{t("Projected BOM")}</Typography>
                        </Box>
                      </Stack>
                      {itemDetails?.costing_status === "Pending" ?
                        <KeyboardArrowRight sx={{ color: "#98A0AC" }} />
                        :
                        <Typography className={classes.costingStatusText} sx={{ backgroundColor: itemDetails?.costing_status === "Rejected" ? "#FF4B4B" : "#5AC782" }}>{itemDetails?.costing_status}</Typography>
                      }
                    </Box>
                    {itemDetails?.costing_status === "Pending" &&
                      <Box mt={1.5}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Button fullWidth variant="contained" className={classes.rejectbtn}>{t("Reject")}</Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button fullWidth variant="contained" className={classes.approvebtn}>{t("Approve")}</Button>
                          </Grid>
                        </Grid>
                      </Box>
                    }
                  </Box>
                </>
              }
              {jobData?.length > 0 && jobData?.map((val) => {
                return (
                  <>
                    <Box height={'16px'} />
                    <Box className={classes.requestPendingBox} p={2} onClick={() => val?.status !== "Pending" ? setIsVisit({ ...isVisit, bool: true, type: "reschedule", data: val }) : false}>
                      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <RescheduleIcon />
                          <Box>
                            <Box display={"flex"}>
                              <Typography className={classes.notesTitle}>{`${moment(val?.execution)?.format("DD MMM YYYY hh:mm a")}`}</Typography>
                            </Box>
                            <Typography className={classes.subText} sx={{ textTransform: "capitalize" }}>{t(`Upcoming ${val?.request_type} Job Visit`)}</Typography>
                          </Box>
                        </Stack>
                        {val?.status === "Pending" ?
                          <KeyboardArrowRight sx={{ color: "#98A0AC" }} />
                          :
                          <Typography className={classes.costingStatusText} sx={{ backgroundColor: val?.status === "Rescheduled" ? "#5078e1" : "#5AC782" }}>{t(val?.status)}</Typography>

                        }
                      </Box>
                      {val?.status === "Pending" &&
                        <Box mt={1.5}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button fullWidth variant="contained" className={classes.reschedulebtn} onClick={() => setIsVisit({ ...isVisit, bool: true, type: "reschedule", data: val })}>{t("Reschedule")}</Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button fullWidth variant="contained" className={classes.approvebtn} onClick={() => setIsVisit({ ...isVisit, bool: true, type: "approve", data: val })}>{t("Approve")}</Button>
                            </Grid>
                          </Grid>
                        </Box>
                      }
                    </Box>
                  </>
                )
              })
              }
              <Box height={'16px'} />
              {(invoice_state?.total_invoice_amount > 0 && invoice_state?.total_invoice_count > 0) &&
                <Box className={classes.nudge_card} onClick={handleInvoiceNudge}>
                  <Stack direction={"row"} columnGap={"12px"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} columnGap={"12px"} alignItems={"center"}>
                      <InvoiceIcon />
                      <Stack direction={"column"} rowGap={"4px"}>
                        <Typography className={classes.invoice_total_amount}>
                          {concat_string(
                            {
                              amount: invoice_state?.total_invoice_amount?.toString(),
                              currency_code: currency?.code,
                            },
                            ["amount", "currency_code"],
                            " ",
                          )}
                        </Typography>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Typography className={classes.invoice_count}>
                            {`${invoice_state?.total_invoice_count} Invoice Total Amount`}
                          </Typography>
                          <KeyboardArrowRightIcon fontSize="small" htmlColor="#4E5A6B" />
                        </Stack>
                      </Stack>
                    </Stack>
                    {/* <Button className={classes.pay_now_button}
                      onClick={(e) => handlePayNow(e)}>
                      {t("Pay Now")}
                    </Button> */}
                  </Stack>
                </Box>
              }
              <Grid container sx={{ backgroundColor: "#fff", borderRadius: "12px" }}>
                <Box className={classes.divider} />
                <div
                  className={classes.content}
                // style={{
                //   overflow: "auto",
                //   maxHeight: size?.height - 100,
                // }}
                >
                  <Grid item xs={12}>
                    <span className={classes.requsettop}>
                      {requestType === "General"
                        ? request?.general_request?.[0]?.general_category_master.type
                        : request?.maintenance_request?.[0]?.maintenance_category_master.type}
                    </span>{" "}
                    <span className={classes.requsettop}>
                      {requestType === "General"
                        ? request?.general_request?.[0]?.general_sub_category_master
                          .type
                        : request?.maintenance_request?.[0]?.maintenance_sub_category_master.type}
                    </span>
                    <Typography className={classes.requsettitle}>
                      {requestType === "General" &&
                        request?.general_request?.[0]?.general_request_units?.[0]?.propertyByID?.name}
                    </Typography>
                    <Typography className={classes.requsettitle}>
                      {requestType === "General"
                        ? request?.general_request?.[0]?.subject
                        : request?.maintenance_request?.[0]?.subject}
                    </Typography>
                    <Box mt={1} maxHeight={"150px"} overflow={"scroll"}>
                      {requestType === "General" ? request?.general_request?.[0]?.description?.split('\n').map((item, i) => <Typography className={classes.requsetsub} key={i}>{item}</Typography>)
                        : request?.maintenance_request?.[0]?.description?.split('\n').map((item, i) => <Typography className={classes.requsetsub} key={i}>{item}</Typography>)
                      }
                    </Box>
                    {/* <Typography className={classes.requsetsub}>
                      {requestType === "General"
                        ? request?.general_request?.[0]?.description
                        : request?.maintenance_request?.[0]?.description}
                    </Typography> */}
                    {requestType === "Maintenance" &&
                      <>
                        {/* <Box height={"10px"} /> */}
                        <Box className={classes.divider} />
                      </>
                    }

                    {request?.maintenance_request?.[0]?.inspection_item_mapping?.name?.length > 0 &&
                      <>
                        <Box pt={2} pb={2}>
                          <Box className={classes.productBox}>
                            <>
                              <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Avatar src={request?.maintenance_request?.[0]?.inspection_item_mapping?.assets !== null ? JSON.parse(request?.maintenance_request?.[0]?.inspection_item_mapping?.assets)?.[0]?.url : "../images/inspectionItem.svg"} variant="square" className={classes.inspectionItemIcon} />
                                  <Box style={{ marginInlineStart: "8px" }}>
                                    <Typography className={classes.productTitle}>{request?.maintenance_request?.[0]?.inspection_item_mapping?.name ?? "-"}</Typography>
                                    <Typography className={classes.subTitle}>{request?.maintenance_request?.[0]?.inspection_item_mapping?.manufacturer?.[0]?.name ?? "-"}</Typography>
                                  </Box>
                                </Stack>
                              </Box>
                              <Divider />

                              <Box p={2}>
                                <Slider {...settings}>
                                  {request?.maintenance_request?.[0]?.location !== null &&
                                    <Grid item xs={6} sm={6} md={6} alignItems="center">
                                      <Stack direction="row" alignItems="center">
                                        <Box style={{ marginInlineEnd: "8px" }}><img src="../images/locationAsset.svg" alt="icon" /></Box>
                                        <Box>
                                          <Typography className={classes.productDetails} noWrap>{request?.maintenance_request?.[0]?.location?.name ?? "-"}</Typography>
                                          <Typography className={classes.productDetailsTitle} noWrap>{t("Location")}</Typography>
                                        </Box>
                                      </Stack>
                                    </Grid>
                                  }

                                  <Grid item xs={6} sm={6} md={6}>
                                    <Stack direction="row" alignItems="center">
                                      <Box style={{ marginInlineEnd: "8px" }}><img src="../images/serialNumber.svg" alt="icon" /></Box>
                                      <Box>
                                        <Typography className={classes.productDetails} noWrap>{request?.maintenance_request?.[0]?.inspection_item_mapping?.serial_number ?? "-"}</Typography>
                                        <Typography className={classes.productDetailsTitle} noWrap>{t("Serial Number")}</Typography>
                                      </Box>
                                    </Stack>
                                  </Grid>


                                  <Grid item xs={6} sm={6} md={6}>
                                    <Stack direction="row" alignItems="center">
                                      <Box style={{ marginInlineEnd: "8px" }}><img src="../images/modelNumber.svg" alt="icon" /></Box>
                                      <Box>
                                        <Typography className={classes.productDetails} noWrap>{request?.maintenance_request?.[0]?.inspection_item_mapping?.model_number ?? "-"}</Typography>
                                        <Typography className={classes.productDetailsTitle} noWrap>{t("Model Number")}</Typography>
                                      </Box>
                                    </Stack>
                                  </Grid>

                                  {request?.maintenance_request?.[0]?.inspection_item_mapping?.last_service?.length > 0 &&
                                    <Grid item xs={6} sm={6} md={6}>
                                      <Stack direction="row" alignItems="center">
                                        <Box style={{ marginInlineEnd: "8px" }}><img src="../images/lastServiceAsset.svg" alt="icon" /></Box>
                                        <Box>
                                          <Typography className={classes.productDetails} noWrap>{moment(request?.maintenance_request?.[0]?.inspection_item_mapping?.last_service?.[0]?.created_at).format("DD MMM YYYY")}</Typography>
                                          <Typography className={classes.productDetailsTitle} noWrap>{t("Last Service")}</Typography>
                                        </Box>
                                      </Stack>
                                    </Grid>
                                  }
                                  {request?.maintenance_request?.[0]?.inspection_item_mapping?.item_condition !== null &&
                                    <Grid item xs={6} sm={6} md={6}>
                                      <Box className={classes.itemBox} mt={0.5}>
                                        <Typography className={classes.itemCondition}>{request?.maintenance_request?.[0]?.inspection_item_mapping?.item_condition}</Typography>
                                      </Box>
                                    </Grid>
                                  }
                                </Slider>
                              </Box>

                            </>
                          </Box>
                        </Box>
                        <Box className={classes.divider} />
                      </>
                    }
                    {request?.maintenance_request?.[0]?.inspection_item_mapping === null &&
                      <>
                        <Box mt={2} className={classes.nonAssetBox}>
                          <Box p={1.5} display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Stack direction="row" spacing={1} alignItems="center">
                              <NonAssetIcon />
                              <Box style={{ marginInlineStart: "8px" }}>
                                <Typography className={classes.title}>{request?.maintenance_request?.[0]?.maintenance_request_units?.[0]?.unit?.name}</Typography>
                                <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                  <Typography className={classes.subTitle}>{request?.maintenance_request?.[0]?.maintenance_request_units?.[0]?.unit?.unit_no}</Typography>
                                  {request?.maintenance_request?.[0]?.maintenance_request_units?.[0]?.unit?.unit_typeByID !== null &&
                                    <Typography className={classes.subTitle} noWrap>{request?.maintenance_request?.[0]?.maintenance_request_units?.[0]?.unit?.unit_typeByID?.name}</Typography>
                                  }
                                </Stack>

                              </Box>
                            </Stack>
                          </Box>
                        </Box>
                        <Box className={classes.divider} mt={2} />
                      </>
                    }
                    <Box
                      display="flex"
                      // justifyContent="space-between"
                      alignItems="center"
                      marginTop="10px"
                      marginBottom="14px"
                    >
                      <Box>
                        <Typography className={classes.requsetsubs}>
                          {t("Property And Unit Details")}
                        </Typography>
                        <Typography className={classes.requsetsubs2}>
                          {requestType === "General" ? request?.general_request?.[0]?.general_request_units?.[0]?.propertyByID?.name : request?.maintenance_request?.[0]?.maintenance_request_units?.[0]?.propertyByID?.name} - {" "}
                          {requestType === "General"
                            ? request?.general_request?.[0]?.general_request_units
                              .length > 1
                              ? request?.general_request?.[0]?.general_request_units
                                .length + " Units"
                              : request?.general_request?.[0]?.general_request_units?.[0]?.unit.name
                            : request?.maintenance_request?.[0]?.maintenance_request_units?.length > 1
                              ? request?.maintenance_request?.[0]?.maintenance_request_units.length + " Units"
                              : request?.maintenance_request?.[0]?.maintenance_request_units?.[0]?.unit.name}
                        </Typography>
                      </Box>
                    </Box>
                    <>
                      <Box className={classes.divider} />
                      <Box height={"12px"} />
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.requsetsubs}>
                            {t("ProblemSince")}
                          </Typography>
                          <Typography className={classes.requsetsubs2}>
                            {" "}
                            <img src="/images/icons8_calendar.svg" alt="img" />{" "}
                            <Box width={'8px'} />
                            <span>
                              &#x202a;{requestType === "General"
                                ?
                                `${format(new Date(request?.general_request?.[0]?.problem_since), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")}`
                                :
                                `${format(new Date(request?.maintenance_request?.[0]?.problem_since), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")}`
                              }&#x202c;
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ borderInlineStart: "1px solid #E4E8EE" }}>
                          <Box sx={{ marginInlineStart: "16px" }}>
                            <Typography className={classes.requsetsubs}>
                              {t("Created On")}
                            </Typography>
                            <Typography className={classes.requsetsubs2}>
                              {" "}
                              <img src="/images/icons8_calendar.svg" alt="img" />{" "}
                              <Box width={'8px'} />
                              <span>
                                &#x202a;{requestType === "General"
                                  ?
                                  format(new Date(request?.general_request?.[0]?.raised_on), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")
                                  :
                                  format(new Date(request?.maintenance_request?.[0]?.raised_on), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")

                                }&#x202c;
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box height={"12px"} />
                    </>

                    {request?.assets != null && request?.assets.length > 0 ? (
                      <>
                        <Box height={"10px"} />
                        <Box className={classes.divider} />
                        <Grid
                          container
                          style={{ marginBottom: "10px", marginTop: "10px" }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{ marginBottom: "8px", marginTop: "8px" }}
                          >
                            <Typography className={classes.requsetsubs}>
                              {t("Attached Images")}
                            </Typography>
                          </Grid>

                          <Box className={classes.overflow} mt={1}>
                            {
                              request?.assets?.map((src, index) => {
                                return (
                                  <Box onClick={() => setImageViewer(true)}>
                                    {src?.file_meta?.file_type === "mp4"
                                      ? <video className={classes.imageStyle}>
                                        <source src={src?.url + "#t=0.001"} type="video/mp4" />
                                      </video>
                                      : src?.file_meta?.file_type === "mpeg"
                                        ? <Box
                                          style={{
                                            height: "75px",
                                            width: "75px",
                                            borderRadius: "6px",
                                            marginTop: "12px",
                                            border: "1px solid grey",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}>
                                          <MicIcon fontSize="large" htmlColor="#5078e1" />
                                        </Box>
                                        : <img
                                          src={generateImageUrl(src.url, img_size.medium_square)}
                                          width="300"
                                          key={index}
                                          className={classes.imageStyle}
                                          alt=""
                                        />
                                    }</Box>
                                )
                              })
                            }
                          </Box>
                        </Grid>
                        <Box className={classes.divider} />
                      </>
                    ) : (
                      <></>
                    )}
                    {requestType !== "General" ? (
                      <>
                        <Box className={classes.divider} />
                        <Box height={"10px"} />
                        <Box>
                          <Box mb={0.5}>
                            <Typography className={classes.requsetsubs}>
                              {t("Preferred Visit Date And Time")}
                            </Typography>

                            {/* <Typography className={classes.requsetsubs2}>
                              {" "}
                              <img
                                src="/images/icons8_calendar.svg"
                                alt="img"
                              /> <Box width={'8px'} />{" "}
                              <span>
                                &#x202a;{requestType === "General"
                                  ?
                                  format(new Date(request?.general_request?.[0]?.preferred_time), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")
                                  :
                                  format(new Date(request?.maintenance_request?.[0]?.preferred_time), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")
                                }&#x202c;
                              </span>
                            </Typography> */}
                          </Box>
                          <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Typography className={classes.requsetsubs2}>
                              {" "}
                              <img
                                src="/images/icons8_calendar.svg"
                                alt="img"
                              /> <Box width={'8px'} />{" "}
                              <span>
                                &#x202a;{requestType === "General"
                                  ?
                                  format(new Date(request?.general_request?.[0]?.preferred_time), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")
                                  :
                                  format(new Date(request?.maintenance_request?.[0]?.preferred_time), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")
                                }&#x202c;
                              </span>
                              {slotsData?.filter((val) => request?.maintenance_request?.[0]?.slot_id === val?.value)?.map((x) => {
                                return (` , ${x?.label}`)

                              })}
                            </Typography>


                          </Stack>
                        </Box>
                        <Box className={classes.divider} mt={1.5} mb={1.5} />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          marginTop="10px"
                        >
                          <Box>
                            <Typography className={classes.requsetsubs}>
                              {t("ContactDetails")}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          marginTop="10px"
                          marginBottom="14px"
                        >
                          <Box>
                            <Avatar className={classes.avatar}>
                              <img src="/images/icons8-call.svg" alt="phone" />
                            </Avatar>
                          </Box>
                          <Box flexGrow={1} sx={{ marginInlineStart: "8px" }}>
                            <Typography className={classes.requsetsubs}>
                              {requestType === "General"
                                ? request?.general_request?.[0]?.contact_name
                                : request?.maintenance_request?.[0]?.contact_name}
                            </Typography>
                            <Stack
                              direction="row"
                              divider={
                                <Divider sx={{ marginRight: "8px", marginLeft: "8px" }} orientation="vertical" flexItem />
                              }
                            // spacing={1}
                            >
                              <Typography className={classes.mobile}>
                                {requestType === "General"
                                  ? request?.general_request?.[0]?.contact_mobile !==
                                    null &&
                                    request?.general_request?.[0]?.contact_mobile !==
                                    ""
                                    ? "+91 " +
                                    request?.general_request?.[0]?.contact_mobile
                                    : ""
                                  : request?.maintenance_request?.[0]?.contact_mobile !== null &&
                                    request?.maintenance_request?.[0]?.contact_mobile !== ""
                                    ? "+91 " +
                                    request?.maintenance_request?.[0]?.contact_mobile
                                    : ""}
                              </Typography>
                              <Typography className={classes.alternateMobile}>
                                {requestType === "General"
                                  ? request?.general_request?.[0]?.contact_alternative_mobile !== null &&
                                    request?.general_request?.[0]?.contact_alternative_mobile !== ""
                                    ? "+91 " +
                                    request?.general_request?.[0]?.contact_alternative_mobile
                                    : ""
                                  : request?.maintenance_request?.[0]?.contact_alternative_mobile !== null &&
                                    request?.maintenance_request?.[0]?.contact_alternative_mobile !== ""
                                    ? "+91 " +
                                    request?.maintenance_request?.[0]?.contact_alternative_mobile
                                    : ""}
                              </Typography>
                            </Stack>
                          </Box>
                        </Box>
                        {/* <Box>
                        <Typography className={classes.requsetsubs}>
                          {"Notes"}
                        </Typography>
                      </Box>
                      <Box>
                        <Box>
                          <Typography>{"ajbcj"}</Typography>
                          <Typography>{"sjsjjsjsj"}</Typography>
                        </Box>
                      </Box>
                      <Divider /> */}

                      </>
                    ) : (
                      <></>
                    )}

                    {
                      // track?.[0]?.notes?.length > 0 &&
                      <>
                        <Box className={classes.divider} mb={1} />
                        <Box>
                          <Typography className={classes.notesTitle}>{t("Latest Update")}</Typography>
                          <Box height={'4px'} />
                          <Box className={classes.statusBox}>
                            {requestType === "General" ?
                              <>
                                <Typography><span className={classes.status}>{t("Status")} :</span>&nbsp;<span className={classes.subStatus}>
                                  {requestType === "General" ? request?.general_request?.[0]?.general_status?.[0]?.type : request?.maintenance_request?.[0]?.maintenance_status?.[0]?.type}
                                </span>
                                </Typography>

                                {(request?.general_request?.[0]?.general_status?.[0]?.notes !== null && request?.general_request?.[0]?.general_status?.[0]?.notes?.length > 0) && request?.general_request?.[0]?.general_status?.[0]?.notes?.filter((x) => { return x?.is_resident })?.length > 0 &&
                                  <Typography className={classes.recentNote}>
                                    {request?.general_request?.[0]?.general_status?.[0]?.notes?.filter((x) => { return x?.is_resident })?.[request?.general_request?.[0]?.general_status?.[0]?.notes?.filter((x) => { return x?.is_resident })?.length - 1]?.notes ?? "-"}
                                  </Typography>
                                }
                              </>

                              :
                              <>
                                <Typography><span className={classes.status}>{t("Status")} :</span>&nbsp;<span className={classes.subStatus}>
                                  {requestType === "General" ? request?.general_request?.[0]?.general_status?.[0]?.type : request?.maintenance_request?.[0]?.maintenance_status?.[0]?.type}
                                </span>
                                </Typography>
                                {request?.maintenance_request?.[0]?.maintenance_status?.[0]?.notes !== null && request?.maintenance_request?.[0]?.maintenance_status?.[0]?.notes?.length > 0 && request?.maintenance_request?.[0]?.maintenance_status?.[0]?.notes?.filter((x) => { return x?.is_resident })?.length > 0 &&
                                  <Typography className={classes.recentNote}>
                                    {request?.maintenance_request?.[0]?.maintenance_status?.[0]?.notes?.filter((x) => { return x?.is_resident })?.[request?.maintenance_request?.[0]?.maintenance_status?.[0]?.notes?.filter((x) => { return x?.is_resident })?.length - 1]?.notes ?? "-"}
                                  </Typography>
                                }
                              </>
                              // <Typography>
                              //   <span className={classes.status}>
                              //     {t("Status")}
                              //   </span>
                              //   &nbsp;
                              //  {accept ?
                              //     <span className={classes.subStatus}>
                              //       {t("Initial costing and BOM is updated")}
                              //     </span> :
                              //     accept ?
                              //       <span className={classes.subStatus}>
                              //         {requestType === "General" ? request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status : request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status}
                              //       </span> :
                              //       <span className={classes.subStatus}>
                              //         {t("In Progress")}
                              //       </span>
                              //   }
                              // </Typography>
                            }
                            {/* <Box height={'8px'} /> */}
                          </Box>
                        </Box>
                      </>
                    }
                    {accept?.toString()?.length > 0 &&
                      <>
                        {accept ?
                          // <Stack direction={"row"} justifyContent={"space-between"}>
                          //   <Stack direction={"column"} spacing={"6px"}>
                          //     <Typography className={classes.lineTwo}>{t("Work Completed")}</Typography>
                          //     <Typography className={classes.lineOne}>on 23 Jan 22, 09:30</Typography>
                          //   </Stack>
                          //   <GreenTick />
                          // </Stack>
                          ""
                          :
                          <>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                              <Stack direction={"column"} spacing={"6px"}>
                                <Typography className={classes.lineTwo}>{t("Upfront Charge Request Is Rejected")}</Typography>
                                <Typography className={classes.lineOne}>{moment(request?.general_request?.[0]?.visiting_charge_request?.[0]?.created_at).format("DD MM YY HH:mm")}</Typography>
                              </Stack>
                              <RedCross />
                            </Stack>
                            <Box height={'16px'} />
                          </>
                        }

                      </>
                    }
                    {accept?.toString()?.length > 0 &&
                      <>
                        {accept ?
                          // <Box className={classes.blueBackground}>
                          //   <Stack direction={"row"} alignItems={"center"} spacing={1}>
                          //     <InspectionReport />
                          //     <Box flexGrow={1}>
                          //       <Typography className={classes.instext}>
                          //         {t("Inspection Report")}
                          //       </Typography>
                          //     </Box>
                          //     <ChevronRight className={classes.arrowIcon} />
                          //   </Stack>
                          // </Box>
                          ""
                          :
                          <>
                            <Box className={classes.blueBackground}>
                              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <Resend />
                                <Box flexGrow={1}>
                                  <Typography className={classes.instext}>
                                    {t("Resend the costing request")}
                                  </Typography>
                                </Box>
                                <ChevronRight className={classes.arrowIcon} />
                              </Stack>
                            </Box>
                            <Box height={'16px'} />
                          </>
                        }

                      </>
                    }
                    {/* <Box className={classes.divider} /> */}

                  </Grid>
                </div>
              </Grid>
              <Box height={"16px"} />
              <Box className={classes.whiteBox}>
                <Box p={2}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    onClick={() => setTrackOpen(!trackOpen)}
                  >
                    <Typography className={classes.text}>
                      {t("Track")}
                    </Typography>
                    {trackOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </Stack>
                </Box>

                {trackOpen &&
                  <>
                    <Divider />
                    <Grid container direction={"column"} spacing={1} p={2}>
                      <div className={classes.createnewsectionlog1}>
                        {track.map((item, index, array) => {
                          return (
                            <Box className={classes.treeBox}>
                              <Box className={classes.border}>
                                {language === "ar" ?
                                  <Avatar
                                    variant="circular"
                                    class={"avatar"}
                                  >
                                    <Avatar
                                      variant="circular"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        backgroundColor: "#5078E1",
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.type === "Open"
                                            ? "images/clockWhite.svg"
                                            : "/images/tick.svg"
                                        }
                                        alt="document"
                                      />
                                    </Avatar>
                                  </Avatar> :
                                  <Avatar
                                    variant="circular"
                                    className={classes.avatars1}
                                  >
                                    <Avatar
                                      variant="circular"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        backgroundColor: "#5078E1",
                                      }}
                                    >
                                      <img
                                        src={
                                          item?.type === "Open"
                                            ? "images/clockWhite.svg"
                                            : "/images/tick.svg"
                                        }
                                        alt="document"
                                      />
                                    </Avatar>
                                  </Avatar>
                                }
                                <Typography
                                  className={classes.title}
                                >
                                  {item?.type}
                                </Typography>
                                <Box height={'4px'} />
                                {
                                  (item?.notes !== null && item?.notes?.length > 0) ? <>
                                    {
                                      item?.notes?.filter((x) => { return x?.is_resident })?.sort((a, b) => { return new Date(b?.created_at) - new Date(a?.created_at) })?.map((x) => {
                                        return (
                                          <>
                                            <Typography
                                              className={classes.notes}
                                            >
                                              {t("Notes:")} {x?.notes ?? "-"}
                                            </Typography>
                                            <Stack direction={"row"} divider={<Divider orientation="vertical" flexItem />} alignItems="center">

                                              {x?.created_at &&
                                                <Typography className={classes.trackLabel}>
                                                  <span>
                                                    {requestType === "General"
                                                      ? moment(
                                                        x?.created_at
                                                      )
                                                        .tz(moment.tz.guess())
                                                        .format("DD MMM YY hh:mm A")
                                                      : moment(
                                                        x?.created_at
                                                      )
                                                        .tz(moment.tz.guess())
                                                        .format("DD MMM YY hh:mm A")}
                                                  </span>
                                                </Typography>
                                              }

                                              <div>
                                                <Typography className={classes.trackLabel2}>
                                                  {requestType === "General" ?
                                                    x?.created_by :
                                                    x?.created_by}
                                                </Typography>
                                              </div>
                                            </Stack>
                                            <Box height={'15px'} />
                                          </>
                                        )
                                      })
                                    }
                                  </> :
                                    <>
                                      <Box height={'4px'} />
                                      <Stack
                                        direction="row"
                                        divider={
                                          <Divider sx={{ marginRight: "8px", marginLeft: "8px" }} orientation="vertical" flexItem />
                                        }
                                      // spacing={1}
                                      >
                                        <Typography
                                          className={classes.dateTime}
                                        >
                                          &#x202a;{moment(item?.created_at)
                                            .tz(moment.tz.guess())
                                            .format("YYYY-MM-DD hh:mm A")}&#x202c;
                                        </Typography>
                                        {item?.closed_byByID?.first_name?.length > 0 &&
                                          <Typography
                                            className={classes.dateTime}
                                          >
                                            {item?.closed_byByID?.first_name}
                                          </Typography>
                                        }
                                      </Stack>

                                    </>
                                }


                                {
                                  requestType === "General" ? request?.general_request?.[0]?.general_status?.length !== index + 1 && <Box height={'13px'} /> : request?.maintenance_request?.[0]?.maintenance_status?.length !== index + 1 && <Box height={'13px'} />
                                }
                              </Box>
                            </Box>
                          )
                        })}
                      </div>
                      {/* {quotationHistory.map((item, index, array) => {
                      return (
                        <Grid item xs={12}>
                          <QuotationHistory
                            index={index}
                            length={array.length}
                          />
                        </Grid>
                      );
                    })} */}
                    </Grid>
                  </>
                }
              </Box>
              <>
                {/* <Box height={'16px'} /> */}
                <>
                  <>
                    <Box className={classes.whiteBox} p={2}>
                      <Box>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          onClick={() => setOpen(open ? false : true)}
                        // onClick={() => setOpen(false)}
                        >
                          <Typography className={classes.text}>{t("KPI")}</Typography>
                          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Stack>
                        <Box p={1.5} className={classes.totaltimeBox} mt={1}>
                          <Stack direction="row" spacing={2} alignItems={"center"}>
                            <img src={"../images/timer.png"} alt="timer" />
                            <Box>
                              <Typography className={classes.timetaken}>{`${serviceLevelData?.total_date_diff} total time taken`}</Typography>
                              {serviceLevelData?.closed_at !== null &&
                                <Typography className={classes.notes}>{`${moment(serviceLevelData?.created_at).format("DD MMM YYYY")} to ${moment(serviceLevelData?.closed_at).format("DD MMM YYYY")}`}</Typography>
                              }
                            </Box>
                          </Stack>
                        </Box>
                      </Box>

                      {open &&
                        <>
                          <Box mt={2}>
                            {serviceLevelData?.closed_at &&
                              <>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                  <Box>
                                    <CompletionIcon />
                                    {/* <Box className={classes.verticalborder}></Box> */}
                                  </Box>
                                  <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#EDF7F8", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                    <Box>
                                      <Typography className={classes.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.closed_at).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                      <Typography className={classes.lineOne}>{t("Completion")}</Typography>
                                    </Box>
                                    <Box sx={{ backgroundColor: "#A6CFD2" }} className={classes.kpibox}>
                                      <Typography className={classes.subLine} sx={{ color: "#6DAFB3" }} noWrap>{serviceLevelData?.total_date_diff}</Typography>
                                    </Box>
                                  </Box>
                                </Stack>
                                <Box className={classes.verticalborder}></Box>
                              </>
                            }
                            {serviceLevelData?.approval_date &&
                              <>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                  <Box>
                                    <ApprovalStartIcon />
                                    {/* <Box className={classes.verticalborder}></Box> */}
                                  </Box>
                                  <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#FFFCEB", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                    <Box>
                                      <Typography className={classes.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.approval_date).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                      <Typography className={classes.lineOne}>{t("Approval For Start")}</Typography>
                                    </Box>
                                    <Box sx={{ backgroundColor: "#E6DEC2" }} className={classes.kpibox}>
                                      <Typography className={classes.subLine} sx={{ color: "#B3A16D" }} noWrap>{serviceLevelData?.approval_time_diff}</Typography>
                                    </Box>
                                  </Box>
                                </Stack>
                                <Box className={classes.verticalborder}></Box>
                              </>
                            }

                            {serviceLevelData?.first_response &&
                              <>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                  <Box>
                                    <FirstResponseIcon />
                                    {/* <Box className={classes.verticalborder}></Box> */}
                                  </Box>
                                  <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#FEF4F4", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                    <Box>
                                      <Typography className={classes.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.first_response).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                      <Typography className={classes.lineOne}>{t("1st Response")}</Typography>
                                    </Box>
                                    <Box sx={{ backgroundColor: "#ECD7D4" }} className={classes.kpibox}>
                                      <Typography className={classes.subLine} sx={{ color: "#B3776D" }} noWrap>{serviceLevelData?.first_response_time_diff}</Typography>
                                    </Box>
                                  </Box>
                                </Stack>
                                <Box className={classes.verticalborder}></Box>
                              </>
                            }
                            {serviceLevelData?.acknowleged_date &&
                              <>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                  <Box>
                                    <AcknowledgeIcon />
                                    {/* <Box className={classes.verticalborder}></Box> */}
                                  </Box>
                                  <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#F6F1FE", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                    <Box>
                                      <Typography className={classes.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.acknowleged_date).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                      <Typography className={classes.lineOne}>{t("Acknowledged On")}</Typography>
                                    </Box>
                                    <Box sx={{ backgroundColor: "#D7CDEA" }} className={classes.kpibox}>
                                      <Typography className={classes.subLine} sx={{ color: "#896DB3" }} noWrap>{serviceLevelData?.acknowleged_time_diff}</Typography>
                                    </Box>
                                  </Box>
                                </Stack>
                                <Box className={classes.verticalborder}></Box>
                              </>
                            }
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                              <Box>
                                <CompletionIcon />
                                {/* <Box className={classes.verticalborder}></Box> */}
                              </Box>
                              <Box p={1.5} display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: "#F2F4F7", borderRadius: "8px", width: "100%" }} alignItems={"center"}>
                                <Box>
                                  <Typography className={classes.lineTwo} noWrap>&#x202a;{moment(serviceLevelData?.created_at).format("DD MMM YYYY , HH:mm a")}&#x202c;</Typography>
                                  <Typography className={classes.lineOne}>{t("Date Opened On")}</Typography>
                                </Box>
                              </Box>
                            </Stack>

                          </Box>

                        </>
                      }
                    </Box>

                  </>

                  <Box>
                    {requestType === "Maintenance" ?
                      <>
                        {initialStatus !== "Closed" && request?.maintenance_request?.[0]?.actual_expense === null &&
                          <Box className={classes.whiteBox} p={2}>
                            <Stack
                              direction={"row"} spacing={"12px"}
                              alignItems={"center"}>
                              <RecordActualExpense />
                              <Box flexGrow={1}>
                                <Typography className={classes.text}>{t("Record Actual Expenses")}</Typography>
                              </Box>
                              <Typography onClick={() => setRecordExpenseDrawer(true)} className={classes.add}>{t("Add")}</Typography>
                            </Stack>
                          </Box>
                        }
                        {request?.maintenance_request?.[0]?.actual_expense !== null &&
                          <Box className={classes.whiteBox} p={2}>
                            <Box className={classes.expenseBox}>
                              <Grid alignItems={"center"} container justifyContent={"space-between"} display={"flex"}>
                                <Grid display={"flex"}>
                                  <Typography className={classes.lineTwo}>{request?.maintenance_request?.[0]?.actual_expense}</Typography>
                                  <Typography className={classes.currency}>{clientCurrency?.code}</Typography>
                                </Grid>
                                <Grid textAlign={"right"}>
                                  <Typography className={classes.update} onClick={() => openExpenseDrawer(request?.maintenance_request?.[0]?.actual_expense)}>{t("Update")}</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            {/* <Typography className={classes.actualExpense} mt={1}>{t("Last Update on")} 22 Jan 23</Typography>  */}
                          </Box>}
                      </>
                      :
                      <>
                        {initialStatus !== "Closed" && request?.general_request?.[0]?.actual_expense === null &&
                          <Box className={classes.whiteBox} p={2}>
                            <Stack
                              direction={"row"} spacing={"12px"}
                              alignItems={"center"}>
                              <RecordActualExpense />
                              <Box flexGrow={1}>
                                <Typography className={classes.text}>{t("Record Actual Expenses")}</Typography>
                              </Box>
                              <Typography onClick={() => setRecordExpenseDrawer(true)} className={classes.add}>{t("Add")}</Typography>
                            </Stack>
                          </Box>
                        }
                        {request?.general_request?.[0]?.actual_expense !== null &&
                          <Box className={classes.whiteBox} p={2}>
                            <Box className={classes.expenseBox}>
                              <Grid alignItems={"center"} container justifyContent={"space-between"} display={"flex"}>
                                <Grid display={"flex"}>
                                  <Typography className={classes.lineTwo}>{request?.general_request?.[0]?.actual_expense}</Typography>
                                  <Typography className={classes.currency}>{clientCurrency?.code}</Typography>
                                </Grid>
                                <Grid textAlign={"right"}>
                                  <Typography className={classes.update} onClick={() => openExpenseDrawer(request?.general_request?.[0]?.actual_expense)}>{t("Update")}</Typography>
                                </Grid>
                              </Grid>
                            </Box>
                            {/* <Typography className={classes.actualExpense} mt={1}>{t("Last Update on")} 22 Jan 23</Typography>  */}
                          </Box>}
                      </>
                    }
                  </Box>

                </>
              </>

            </div>
            {request?.allow_cancel_request && (
              <Grid item xs={12}>
                <div
                  xs={12}
                  style={{
                    padding: "12px",
                    backgroundColor: "white",
                    boxShadow: "0px -1px 6px #00000021",
                    position: "absolute",
                    bottom: 0,
                    width: "-webkit-fill-available"
                  }}
                >
                  <Button
                    fullWidth
                    className={classes.cancelbtn}
                    onClick={() => setCancel(true)}
                  >
                    {t("Cancelrequest")}
                  </Button>
                </div>
              </Grid>
            )}
            {/* {accept &&
              <Box p={2} className={classes.payBackground}>
                <Grid container>
                  <Grid item xs={6}>
                    <Stack direction={"column"}>
                      <Typography className={classes.label}>{t("Total Amount")}</Typography>
                      <Typography className={classes.amount}>{request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.amount}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={() => history.push("/maintenancePreview")}
                      className={classes.payButton}
                      fullWidth
                      variant="contained">
                      {t("Proceed to pay")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            } */}
          </>
        )}
      </Container>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderCancelForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderCancelForm()}
        </Drawer>
      </Hidden>
      {/* ACCEPT DRAWER */}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={acceptDrawer}
          onClose={() => setAcceptDrawer(false)}
        >
          <Box p={2}>
            <Box mb={3} textAlign={"right"} onClick={() => setAcceptDrawer(false)}>
              <ClearIcon />
            </Box>
            <Box textAlign={"center"}>
              <BigGreenTick />
            </Box>
            <Box textAlign={"center"}>
              <Typography className={classes.recentNote}>{t("Thank You For accepting the costing")} <br /> {t("We will schedule your work soon")}</Typography>
            </Box>
          </Box>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={acceptDrawer}
          onClose={() => setAcceptDrawer(false)}
        >
          <Box p={2}>
            <Box mb={3} textAlign={"right"} onClick={() => setAcceptDrawer(false)}>
              <ClearIcon />
            </Box>
            <Box textAlign={"center"}>
              <BigGreenTick />
            </Box>
            <Box textAlign={"center"}>
              <Typography className={classes.recentNote}>{t("Thank You For accepting the costing")} <br /> {t("We will schedule your work soon")}</Typography>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      {/* REJECT DRAWER */}
      <DialogDrawer
        open={rejectDrawer}
        onClose={() => setRejectDrawer(false)}
        header="Reject"
        component={
          <>
            <TextBox
              isRequired
              color
              labelColor={"#98A0AC"}
              label={t("Reason for Reject")}
              placeholder={t("Reason")}
              value={reason}
              onChange={(value) => setReason(value.target.value)}
              multiline />
            <Box className={classes.divider} mt={4} mb={2} />
            <Grid container>
              <Grid item xs={12}>
                <Button onClick={() => {
                  acceptCostingRequest("Rejected")
                  setAccept(false)
                }}
                  className={classes.button}
                  fullWidth variant="contained">
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      {/* RECORD EXPENSE DRAWER */}
      <DialogDrawer
        open={recordExpenseDrawer}
        onClose={closeExpenseDrawer}
        header={(request?.maintenance_request?.[0]?.actual_expense === null || request?.general_request?.[0]?.actual_expense === null) ? t("Add expense") : t("Update Expense")}
        component={
          <>
            <Stack direction={"row"} spacing={1} pb={3}>
              <RecordExpenseIcon />
              <Stack direction={"column"}>
                <Typography className={classes.cost}>{t("Add Your Expense")}</Typography>
                <Typography className={classes.addSpending}>{t("Your expense will be added to your spendings")}</Typography>
              </Stack>
            </Stack>
            <TextBox
              placeholder={t("Add expense")}
              endAdornment={(
                <Typography className={classes.currency}>{clientCurrency?.code}</Typography>
              )}
              value={actualExpense}
              onChange={(e) => setActualExpense(e?.target?.value)}
            />
            <Grid pt={4} container>
              <Grid item xs={12}>
                <Button
                  onClick={updateActualExpense}
                  className={classes.expenseButton}
                  fullWidth variant="contained">
                  {(request?.maintenance_request?.[0]?.actual_expense === null || request?.general_request?.[0]?.actual_expense === null) ? t("Add expense") : t("Update Expense")}
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <DialogDrawer
        open={showCharges}
        onClose={() => setShowCharges(false)}
        header={t("Upfront Charges")}
        component={
          <>
            <Box position={"relative"}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <UpfrontChargesIcon />
                  <Box>
                    {requestType === "Maintenance" &&
                      <Typography className={classes.notesTitle}>{`${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""}`}</Typography>
                    }
                    {requestType === "General" &&
                      <Typography className={classes.notesTitle}>{`${request?.general_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.general_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""}`}</Typography>
                    }
                    <Typography className={classes.subText}>{t("Upfront Charges")}</Typography>
                  </Box>
                </Stack>
                <Typography className={classes.costingStatusText} sx={{ backgroundColor: (request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Rejected" || request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Rejected") === "Rejected" ? "#FF4B4B" : "#5AC782" }}>{requestType === "Maintenance" ? t(request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status) : t(request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status)}</Typography>
                {/* <KeyboardArrowRight sx={{ color: "#98A0AC" }} /> */}
              </Box>
              <Box mt={2} height="200px">
                {requestType === "Maintenance" && request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.notes !== null &&
                  <>
                    <Box mb={0.5}>
                      <Typography className={classes.productTitle}>{t("Notes")}</Typography>
                    </Box>
                    <ShowMoreText
                      lines={2}
                      more="Show More"
                      less="Show Less"
                      className={classes.requestNo}
                      anchorClass={classes.seeMoreLessTextStyle}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      <Typography className={classes.seeMoreLessTextStyle}>
                        {request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.notes ?? "-"}
                      </Typography>
                    </ShowMoreText>
                  </>
                }
                {requestType === "General" && request?.general_request?.[0]?.visiting_charge_request?.[0]?.notes !== null &&
                  <>
                    <Box mb={0.5}>
                      <Typography className={classes.productTitle}>{t("Notes")}</Typography>
                    </Box>
                    <ShowMoreText
                      lines={2}
                      more="Show More"
                      less="Show Less"
                      className={classes.requestNo}
                      anchorClass={classes.seeMoreLessTextStyle}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      <Typography className={classes.seeMoreLessTextStyle}>
                        {request?.general_request?.[0]?.visiting_charge_request?.[0]?.notes ?? "-"}
                      </Typography>
                    </ShowMoreText>
                  </>
                }
                <Box mt={1} mb={1} className={classes.dotted} />
                <Box mt={1}>
                  <Typography className={classes.productTitle}>{t("Summary")}</Typography>
                </Box>
                <Box mt={1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <Typography className={classes.requestNo}>{t("Upfront Charges and Payments")}</Typography>
                    </Grid>
                    {requestType === "Maintenance" &&
                      <Grid item xs={4} textAlign={"right"}>
                        <Typography className={classes.requestNo}>{`${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""} `}</Typography>
                      </Grid>
                    }
                    {requestType === "General" &&
                      <Grid item xs={4} textAlign={"right"}>
                        <Typography className={classes.requestNo}>{`${request?.general_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.symbol ?? ""} ${request?.general_request?.[0]?.visiting_charge_request?.[0]?.amount}`}</Typography>
                      </Grid>
                    }
                  </Grid>
                </Box>
                <Box mt={1} mb={1} className={classes.dotted} />
                <Box mt={1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <Typography className={classes.productTitle}>{t("Total Amount")}</Typography>
                    </Grid>
                    {requestType === "Maintenance" &&
                      <Grid item xs={4} textAlign={"right"}>
                        <Typography className={classes.productTitle}>{`${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""} `}</Typography>
                      </Grid>
                    }
                    {requestType === "General" &&
                      <Grid item xs={4} textAlign={"right"}>
                        <Typography className={classes.productTitle}>{`${request?.general_request?.[0]?.visiting_charge_request?.[0]?.amount} ${request?.general_request?.[0]?.visiting_charge_request?.[0]?.currency_masterByID?.code ?? ""} `}</Typography>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Box>
            </Box>
            {(request?.maintenance_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Pending" || request?.general_request?.[0]?.visiting_charge_request?.[0]?.costing_status === "Pending") &&
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button variant="contained" fullWidth className={classes.rejectbtn} onClick={() => setRejectDrawer(true)}>{t("Reject")}</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" fullWidth className={classes.approvebtn} onClick={() => acceptCostingRequest("Approved")}>{t("Approve")}</Button>
                  </Grid>
                </Grid>
              </Box>
            }
          </>
        }
      />
      <DialogDrawer
        open={isVisit?.bool}
        onClose={() => { return (setIsVisit({ ...isVisit, bool: false }), setRescheduleData({ visitDate: new Date() })) }}
        header={t("Upcoming Visit")}
        component={
          <>
            <Box position={"relative"} height={isVisit?.type === "reschedule" ? (match ? (isVisit?.data?.status === "Rescheduled" ? "auto" : "500px") : "auto") : (isVisit?.data?.status === "Approved" ? "auto" : "400px")} overflow={"scroll"}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <RescheduleIcon />
                  <Box>
                    {requestType === "Maintenance" &&
                      <Box display={"flex"}>
                        <Typography className={classes.notesTitle}>{`${moment(isVisit?.data?.execution).format("DD MMM YYYY hh:mm a")}`}</Typography>
                      </Box>
                    }
                    <Typography className={classes.subText}>{`Upcoming ${isVisit?.data?.request_type} job`}</Typography>
                  </Box>
                </Stack>
                {isVisit?.data?.status !== "Pending" &&
                  <Typography className={classes.costingStatusText} style={{ backgroundColor: isVisit?.data?.status === "Rescheduled" ? "#5078e1" : "#5AC782" }}>{isVisit?.data?.status}</Typography>

                }
              </Box>

              <>
                <Box mt={2} className={classes.newReschedulebox}>
                  {isVisit?.data?.rescheduled_datetime !== null &&
                    <Box mb={1}>
                      <Typography className={classes.label}>{t("Rescheduled Date & Time")}</Typography>
                      <Box mt={0.5}>
                        <Typography className={classes.notesTitle}>{moment(isVisit?.data?.rescheduled_datetime).format("DD MMM YYYY")} , {isVisit?.data?.slot_name}</Typography>

                      </Box>
                    </Box>
                  }
                  <Typography className={classes.label}>{t("Resource Name")}</Typography>
                  <Box mt={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar src={isVisit?.data?.image_url} />
                      <Box>
                        <Typography className={classes.notesTitle}>{isVisit?.data?.resource_name}</Typography>
                        <Typography className={classes.label}>{isVisit?.data?.resource_job}</Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box mt={1}>
                    <Typography className={classes.label}>{t("Job Detail")}</Typography>
                    <Box mt={1}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <JobIcon />
                        <Box>
                          <Typography className={classes.notesTitle} sx={{ textTransform: "capitalize" }}>{isVisit?.data?.request_type}</Typography>
                          <Typography className={classes.label}>{isVisit?.data?.job_no}</Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
                {isVisit?.data?.status === "Pending" && isVisit?.type === "reschedule" ?
                  <Box mt={2} className={classes.newReschedulebox} mb={6}>
                    <Box>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <RescheduleTextIcon />
                        <Typography className={classes.rescheduleText}>{t("To reschedule, Please select your preferred date and time")}</Typography>
                      </Stack>
                    </Box>
                    <Box mt={1}>
                      <Box mt={1}>
                        <DatePickerNew
                          isRequired
                          // isTime
                          disableFuture={true}
                          disablePast={false}
                          minDate={new Date()}
                          label={t("Date")}
                          placeholder={t("Choose Date")}
                          value={rescheduleData?.visitDate}
                          onChange={(value) =>
                            setRescheduleData({ ...rescheduleData, visitDate: value, selectedSlot: "" })
                          }
                          labelColor="#071741"
                        // isError={props?.request?.error?.preferredTime?.length > 0}
                        // errorMessage={props?.request?.error?.preferredTime}
                        />
                      </Box>
                      <Box mt={1}>
                        <Typography className={classes.slotTitle}>{t("Time Slots")}<Typography variant="caption" color="error" sx={{ marginLeft: "px" }}>*</Typography></Typography>
                      </Box>
                      <Box mt={1} mb={2}>
                        <Grid container spacing={1} alignItems={"center"}>
                          {slotsData?.map((x) => {
                            return (
                              <Grid item xs={6} sm={6} md={3} lg={3} onClick={() => handleChangeSlot(x)}>
                                <Box className={rescheduleData?.selectedSlot?.value === x?.value ? classes.selectedSlotBox : classes.slotBox}
                                  sx={{ textAlign: !Boolean(x?.time) && "center !important" }}>
                                  <Typography className={classes.slotTime} style={{ color: rescheduleData?.selectedSlot?.value === x?.value ? "#5078E1" : "#091B29" }}>{x?.label}</Typography>
                                </Box>
                              </Grid>
                            )
                          })}

                        </Grid>
                      </Box>
                      {/* <Box height={"30px"}></Box> */}
                    </Box>
                  </Box>
                  : isVisit?.data?.status === "Pending" &&
                  <Box mt={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <DatePickerTimeNew
                          label={isVisit?.type === "reschedule" ? t("New visit date and time") : t("Visit date and time")}
                          backgroundColor={"#FFFFFF"}
                          onChange={(value) => setIsVisit({ ...isVisit, rescheduleDateTime: value })}
                          value={new Date(rescheduleData?.visitDate)}
                          format={"dd/MM/yyyy hh:mm a"}
                          isReadOnly={(isVisit?.data?.status !== "Pending" && isVisit?.type === "reschedule") ? true : isVisit?.type === "approve" ? true : false}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                }
              </>

            </Box>
            {isVisit?.data?.status === "Pending" &&
              <Box className={classes.bottomfix}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button variant="contained" fullWidth className={isVisit?.type === "approve" ? classes.approvebtn : classes.reschedulebtn} onClick={() => handleReschedule(isVisit?.type === "approve" ? "Approved" : "Rescheduled")}>{isVisit?.type === "approve" ? t("Approve") : t("Reschedule")}</Button>
                  </Grid>
                </Grid>
              </Box>
            }
          </>
        }
      />
      {/* <DialogDrawer
            height={"300px"}
            open={view?.bool}
            isnotTitle
            padding={"16px"}
            component={<> {
              view?.data?.file_meta?.file_type === "mp4" ? 
              <video
              className={classes.imageStyleView}
                  controls
              >
                  <source src={view?.data?.url} type="video/mp4" />
              </video> : 
              <img
                src={view?.data?.url}
            className={classes.imageStyleView}
                alt=""
              />
           }</>}
            onClose={() => viewDialog(true, "")}
          /> */}
      {/* <ImageViwer isOpen={view?.bool} onClose={() => viewDialog(true, "")} currImg={view?.data} onClickPrev={onClickPrev} onClickNext={onClickNext} images={request?.assets}/> */}
      {view?.bool && (
        <ImageViewer
          src={request?.assets?.map((x) => x?.url)
          }
          currentIndex={view?.data}
          onClose={() => viewDialog(true, "")}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}

      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            title={t("Images")}
            assets={request?.assets?.map((val) => {
              return {
                meta_data: {
                  file_type: val?.file_meta?.file_type ?? "image",
                },
                file_url: val?.url,
              };
            })}
            onClose={() => setImageViewer(false)}
          />
        }
      />
    </div>
  );
};
export default withNamespaces("request")(RequestDetails)
