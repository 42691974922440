import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import Communicate from "../../assets/communicate";
import BoxLayout from "./components/boxLayout";
import RequestBox from "./components/requestBox";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dispute from "../../assets/dispute";
import RepairRequest from "../../assets/repairRequest";
import {
  DialogDrawer, Filter,
  LoderSimmer
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";

import { Close } from "@mui/icons-material";
import ArrowRight from "../../assets/arrowRightCircle";
import { FilterIcon } from "../../assets/filterIcon";
import MaintenanceRequest from "../../assets/maintenanceReq";
import MaintenanceNewCard from "../../components/maintenanceNewCard";
import NoDataFound from "../../components/noDataFound/noDataFound";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  enumCatagory,
  enumSelect,
  FontFamilySwitch,
  NetWorkCallMethods,
  remCalculate,
  requsetStatus,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme.palette.dashboard.primary,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  bottombar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    left: "0px",
    right: "0px",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
  },
  menuContainer: {},
  menuText: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  menuItem: {
    position: "absolute",
    background: theme.palette.common.white,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
      left: "60%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  containers: {
    [theme.breakpoints.down("sm")]: {
      "&.MuiContainer-root": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },

      overflow: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      "&.MuiContainer-root": {
        paddingLeft: "12px",
        paddingRight: "12px",
      },

      overflow: "hidden",
    },
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
  },
  drawer: {
    "& .MuiPaper-root-MuiDrawer-paper": {
      // "background-color": "transparent",
      // "box-shadow": "none"
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  filter: {
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "8px",
  },
  scroll: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  // MY CSS
  drawerHeader: {
    color: theme.typography.color.primary,
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
  },
  clearIcon: {
    color: "#7C8594",
  },
  request: {
    color: theme.typography.color.primary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    marginInlineStart: "8px"
  },
  requestTitle: {
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  service: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  example: {
    color: theme.typography.color.Tertiary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular
  },
  scanAssetBox: {
    backgroundImage: `url("../images/scanInspectionItemQR.svg")`,
    background: "no-repeat",
    backgroundColor: "#FFF8E7",
    border: "1px solid #FFEBB7",
    borderRadius: "12px",
    height: "125px",
    padding: "12px",
    // boxShadow: "0px 3px 15px #4250682E",
    position: "relative"
  },
  searchAssetBox: {
    backgroundImage: `url("../images/searchInspectionItem.svg")`,
    background: "no-repeat",
    backgroundColor: "#E6EFFF",
    border: "1px solid #C9D9F6",
    borderRadius: "12px",
    height: "125px",
    padding: "12px",
    // boxShadow: "0px 3px 15px #4250682E",
    position: "relative"
  },
  assettext: {
    color: "#031629",
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().bold
  },
  fixbtn: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    padding: "12px"
  },
  requestHdr: {
    fontSize: remCalculate(18),
    fontFamily: FontFamilySwitch()?.extraBold,
    color: theme.typography.color.primary
  },
  closeIconStyle: {
    color: "#7C8594",
    cursor: "pointer"
  }
}));

const RequestHomeScreen = (props) => {
  const { t = () => false, i18n, handleTopBarState } = props;
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [offSet, setOffSet] = React.useState(0);
  const [requestList, setRequestList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [requestsListCount, setRequestsListCount] = React.useState({
    totalCount: 0,
    maintananceCount: 0,
    generalCount: 0
  });
  const [loading, setLoading] = React.useState(true);
  const [statusType, setStatusType] = React.useState([]);
  const { state } = useLocation();
  const language = localStorage.getItem("i18nextLng")
  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };


  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };

  //get requset
  const getAllRequsetData = (units, type, gen, offset, filter) => {
    const requestPayload = {
      tenantId: `${config.tenantid}`,
      request_units: units,
      request_type: type,
      request_status: gen,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/request`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((response) => {
        setRequestsListCount({ totalCount: response?.data?.totalCount?.toString().padStart(2, '0') ?? "", maintananceCount: response?.data?.maintenanceCount?.toString().padStart(2, '0') ?? "", generalCount: response?.data?.generalCount?.toString().padStart(2, '0') ?? "" });
        if (filter) {
          setRequestList(response?.data?.data ?? []);
        } else {
          setRequestList(requestList.concat(response?.data?.data));
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // alert.setSnack({
        //   ...alert,
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Some Thing Went Wrong.",
        // });
      });
  };
  //get all units by user id
  const getAllunitsByuserId = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let data =
          response?.data?.data.length > 0
            ? response?.data?.data?.map((x) => x?.agreement_unit).flat()
            : [];
        getEnum(data?.length > 0 && data?.map((x) => x?.id));
        //get units for filter
        const allunits =
          data?.length > 0 &&
          data?.map((val, index) => {
            let _d;
            try {
              _d = {
                label: val?.name,
                value: String(val.id),
              };
            } catch (err) { }

            return _d;
          });
        setUnitsfil(allunits);
      })
      .catch((error) => {
        if (error?.code === 503) {
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }
      });
  };

  React.useEffect(() => {
    handleTopBarState({
      t: t,
      note: true,
      i18n: i18n,
      // new: requestsListCount?.unread,
    });
    getAllunitsByuserId();
    // eslint-disable-next-line
  }, []);

  const filterData = [
    {
      id: "1",
      title: t("Request Type"),
      key: "request_type",
      filterType: "CHECKBOX",
      values: [
        {
          label: t("General"),
          value: requsetStatus.general,
        },
        {
          label: t("Maintenance"),
          value: requsetStatus.maintenance,
        },
      ],
    },

    {
      id: "2",
      title: t("Units"),
      key: "units",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    {
      id: "3",
      title: t("Request Status"),
      key: "general",
      filterType: "CHECKBOX",
      values: statusType,
    },
  ];

  // DUMMY DATA

  const drawerData = [
    {
      image: <RepairRequest />,
      request: t("MaintenanceRequest"),
      service: t("Maintenance Description"),
      type: "Maintenance",
      // example: t("Eg. Plumbing, Electrical"),
      route: Routes.maintenanceParent
    },
    {
      image: <Dispute />,
      request: t("GeneralRequest"),
      type: "General",
      service: t("General Description"),
      // example: t("Eg. Related to Invoice, Agreement etc"),
      route: Routes.general
    },
  ];

  const applyFilter = (data) => {
    if (data?.request_type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonerequesttype"),
      });
      return false;
    }
    if (data?.units?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    if (data?.general?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonegeneralstatus"),
      });
      return false;
    }

    getAllRequsetData(data?.units, data?.request_type, data?.general, 0, true);
    setOffSet(0);
    setDrawer(false);
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 10);
    getAllRequsetData(
      selectedFilter?.units,
      selectedFilter?.request_type,
      selectedFilter?.general,
      offSet + 10,
      false
    );
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.general_maintenance_type]);
    setStatusType(stayType?.general_maintenance_type);
    getAllRequsetData(
      data,
      state ? state?.request_type : [requsetStatus.general, requsetStatus.maintenance],
      ["Open","Reopened","Assigned"],
      0,
      false
    );
    setSelectedFilter({
      units: data,
      general: ["Open","Reopened","Assigned"],
      request_type: state
        ? state?.request_type
        : [requsetStatus.general, requsetStatus.maintenance],
    });
  };
  const handleroutes = (data, type) => {
    history.push({
      pathname: data?.route,
      state: {
        maintenanceRequestType: type
      }
    })
  }
  return (
    <Box
      height={size?.height - 194}
      sx={{ backgroundColor: "#f6f6f6" }}
    >
      <Container className={classes.root} maxWidth="sm">
        {/* <TopNavBars 
       t={t}
       i18n={i18n}
        note={true}
        count="4"
        handleClicknote={() => history.push(Routes.notification)}
        document={() => history.push(Routes?.fileManager)}
      /> */}
        <Box
          display="flex"
          alignItems="center"
          sx={{
            padding: "12px",
            paddingTop: "20px"
          }}
        >
          {/* Total Request */}
          <Box flexGrow={1}>
            <Typography className={classes.total}>
              {t("Facility Management")}&nbsp;({requestsListCount?.totalCount ?? "0"})
            </Typography>
          </Box>
          <Box>
            {/* Filter*/}

            <IconButton className={classes.filter} onClick={draweropen}>
              <Badge variant="dot" color="primary">
                <FilterIcon />
                {/* <img src="/images/icons8-funnel.svg" alt="filter" /> */}
              </Badge>
            </IconButton>
          </Box>
        </Box>

        {/* FIRST COMPONENT */}



        <div>
          <Grid className={classes.screenScrollEffect}>
            <Grid container >
              <Grid className={classes.screen} item xs={12}>
                {loading ? (
                  <Box p={2}>
                    <LoderSimmer count={10} />
                  </Box>
                ) : (
                  <>
                    {requestList?.length > 0 ? (
                      <InfiniteScroll
                        dataLength={requestList?.length ?? null}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 220}
                        className={classes.scroll}
                      >
                        <Box p={2}>
                          <div style={{ marginBottom: "16px" }}>
                            <BoxLayout padding={"16px"}>
                              <Grid container>
                                <Grid item lg={6} xs={6}>
                                  <RequestBox
                                    title={t("General")}
                                    count={requestsListCount?.generalCount}
                                    icon={<Communicate />}
                                  />
                                </Grid>
                                <Grid item lg={6} xs={6}>
                                  <RequestBox
                                    color="#DBF0F1"
                                    title={t("Maintenance")}
                                    count={requestsListCount?.maintananceCount}
                                    icon={<MaintenanceRequest />}
                                  />
                                </Grid>
                              </Grid>
                            </BoxLayout>
                          </div>
                          <Box sx={{ backgroundColor: "#F2F4F7" }}>
                            {requestList?.map((value, index) => {
                              return (
                                <Box mb={requestList?.length - 1 !== index ? 2 : 0}>
                                  <BoxLayout>
                                    <MaintenanceNewCard
                                      t={t}
                                      onClick={() => {
                                        history.push(
                                          Routes.requestDetails
                                          +
                                          "?requestId=" +
                                          value?.id +
                                          "&requestNo=" +
                                          value?.id +
                                          "&requestType=" +
                                          value?.request_type +
                                          "&status=" +
                                          value?.request_status +
                                          "&job_count=" +
                                          value?.job_count
                                        );
                                      }}
                                      sub_category={value?.sub_category}
                                      category={value?.category}
                                      image={value?.image_url}
                                      image_type={value?.image_url?.split('.')?.pop()?.toLowerCase() ?? null}
                                      asset_name={value?.asset}
                                      header={value?.subject}
                                      subHeader={value?.request_no}
                                      property_name={value?.property_name}
                                      date={value?.raised_on}
                                      unit_name={value?.units.name}
                                      type={value?.category}
                                      unitNumber={value?.units?.unit_no}
                                      request_status={value?.request_status}
                                      action={value?.approval_status}
                                      unit_type={value?.units?.unit_type_name}
                                    />
                                  </BoxLayout>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </InfiniteScroll>
                    ) : (
                      <Box p={2}>
                        <NoDataFound
                          backgroundColor="#fff"
                          height={size?.height - 300}
                          content={t("No Data Found!")}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12} style={{ position: "relative" }}>
                <Box sx={{ display: "flex", float: "right" }}>
                  <Box>
                    {/* <Fab
                      color="primary"
                      className={classes.fab}
                      aria-label="add"
                      onClick={handleToggle}
                    >
                      {open ? <CloseIcon /> : <AddIcon />} */}
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <Box
                        sx={{
                          display: "flex",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        <Box>
                          {/* <Fab
                              color="primary"
                              className={classes.fab}
                              aria-label="add"
                              onClick={handleToggle}
                            >
                            {open ? <CloseIcon /> : <AddIcon />} */}
                          <DialogDrawer
                            // height={"300px"}
                            maxWidth={"sm"}
                            open={open}
                            onClick={handleClose}
                            isnotTitle
                            // header={t("Raise Request")}
                            component={
                              <>
                                <Paper elevation={0}>
                                  <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes.requestHdr}>{t("Create Request")}</Typography>
                                    <Close className={classes?.closeIconStyle} onClick={handleClose} />
                                  </Box>
                                  <Box mt={2}>
                                    {drawerData?.map((datas) => {
                                      return (
                                        datas?.type === "Maintenance" ?
                                          <Box>
                                            <Typography
                                              className={classes.requestTitle}
                                            >
                                              {t("Service")}/{datas.request}
                                            </Typography>
                                            <Box mt={0.5}>
                                              <Grid container spacing={2}>
                                                <Grid item xs={6} md={6} sm={6} mt={1}>
                                                  <Box className={classes.scanAssetBox} style={{ backgroundPosition: language === "ar" ? "10%" : "90%" }} position="relative" onClick={() => handleroutes(datas, "scan")}>
                                                    <Typography className={classes.assettext}>{t("Scan Asset / Item QR Code")}</Typography>
                                                    <Box className={classes.fixbtn}><ArrowRight /></Box>
                                                  </Box>
                                                </Grid>
                                                <Grid item xs={6} md={6} sm={6} mt={1}>
                                                  <Box className={classes.searchAssetBox} style={{ backgroundPosition: language === "ar" ? "10%" : "90%" }} position="relative" onClick={() => handleroutes(datas, "search")}>
                                                    <Typography className={classes.assettext}>{t("Search Asset / Item")}</Typography>
                                                    <Box className={classes.fixbtn}><ArrowRight /></Box>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Box>
                                          :
                                          <Box
                                            mt={2}
                                            p={2}
                                            display="flex"
                                            justifyContent={"space-between"}
                                            alignItems={"center"}
                                            sx={{
                                              border: "1px solid #E4E8EE",
                                              borderRadius: "12pt",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              history.push(
                                                datas?.route
                                              );
                                            }}
                                          >
                                            <Stack direction="row" alignItems="center">
                                              <Box style={{ marginInlineEnd: "8px" }}>{datas.image}</Box>
                                              <Typography
                                                className={classes.request}
                                              >
                                                {datas.request}
                                              </Typography>
                                            </Stack>
                                            <KeyboardArrowRightIcon />
                                            {/* <Grid container justifyContent={"end"}>
                                            <Grid item lg={3} xs={4}>
                                              {datas.image}
                                            </Grid>
                                            <Grid
                                              item
                                              lg={9}
                                              xs={8}
                                              sx={{ lineHeight: "20px" }}
                                            >
                                              <Typography
                                                className={classes.request}
                                              >
                                                {datas.request}
                                              </Typography>
                                              <Box mt={1} display="flex">
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    lg={10}
                                                    xs={10}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.service
                                                      }
                                                    >
                                                      {datas.service}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid textAlign={"right"} item lg={2} xs={2}>
                                                    <Box class={"arrow"}>
                                                      <KeyboardArrowRightIcon />
                                                    </Box>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                              <Typography
                                                className={classes.example}
                                              >
                                                {datas.example}
                                              </Typography>
                                            </Grid>
                                          </Grid> */}
                                          </Box>
                                      );
                                    })}
                                  </Box>
                                </Paper>
                              </>
                            }
                            onClose={() => setOpen(false)}
                          />
                          {/* </Fab> */}
                        </Box>
                      </Box>
                    </Grid>
                    {/* </Fab> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Hidden smDown>
          <Dialog
            open={drawer}
            onClose={drawerclose}
            fullWidth={true}
            maxWidth="sm"
            className={classes.dialog}
          >
            <Grid container>
              <Grid item xs={12}>
                <Filter
                  filterData={filterData}
                  onGoBack={drawerclose}
                  selectedList={filterData[0]}
                  filters={{
                    agreement: selectedFilter?.agreement,
                    general: selectedFilter?.general,
                    units: selectedFilter?.units,
                    request_type: selectedFilter?.request_type,
                    maintanance: selectedFilter?.maintanance,
                  }}
                  unitsfil={unitsfil}
                  setUnitsfil={setUnitsfil}
                  onApply={applyFilter}
                  setRequestList={setRequestList}
                  onClose={drawerclose}
                  defaultData={selectedFilter}
                  request
                  setSelectedFilter={setSelectedFilter}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            className={classes.filterDrawer}
            open={drawer}
            onClose={drawerclose}
          >
            <Grid container>
              <Grid item xs={12}>
                <Filter
                  filterData={filterData}
                  onGoBack={drawerclose}
                  selectedList={filterData[0]}
                  filters={{
                    agreement: selectedFilter?.agreement,
                    general: selectedFilter?.general,
                    units: selectedFilter?.units,
                    request_type: selectedFilter?.request_type,
                    maintanance: selectedFilter?.maintanance,
                  }}
                  unitsfil={unitsfil}
                  setUnitsfil={setUnitsfil}
                  onApply={applyFilter}
                  onClose={drawerclose}
                  defaultData={selectedFilter}
                  request
                  setSelectedFilter={setSelectedFilter}
                />
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>

        <Grid container>
          <Grid item xs={12} style={{ position: "relative" }}>
            <Box sx={{ display: "flex", float: "right" }}>
              <Box>
                <Fab
                  color="primary"
                  className={classes.fab}
                  aria-label="add"
                  onClick={handleToggle}
                >
                  <AddIcon />
                </Fab>
              </Box>
            </Box>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );
};
export default withNamespaces("request")(RequestHomeScreen);
