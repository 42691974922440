/* eslint-disable array-callback-return */
import {
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import React, { useContext } from "react";
import { withNamespaces } from "react-i18next";
import CommitteeIcon from "../../assets/commiteeIcon";
import ContactMeIcon from "../../assets/contactMe";
import PlaceOfInterestIcon from "../../assets/placeOfInterest";
import YourTownshipIcon from "../../assets/yourTownship";
import {
  CarouselSliders,
  DialogDrawer,
  NoService
} from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import UnitDetails from "../agreement/component/UnitDetails";
// import AmenitiesScroller from "./components/AmenitiesScroller";
// import { ArrowRightOutline } from "../../assets/community/ArrowRightOutline";
// import { Notifications } from "../../assets/community/Notifications";
import { useHistory } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ServiceCard from "../../components/serviceCard";
import { AgreementSimmer } from "../../components/simmer/agreementSimmer";
import { AlertContext } from "../../contexts";
import { ADD_LIKE, UPDATELIKE } from "../../graphql/mutations";
import { Routes } from "../../router/routes";
import AmenityBookingNew from "../amenityBookingNew/amenityBookingNew";
import { NewCommunityCard } from "../communityDiscussion/components/communityNewCard";
import CommunityMemberModel from "./components/CommunityMemberModel";
import { useStyles } from "./styles";

const Service = ({ props, t = () => false, i18n, handleTopBarState, theme }) => {
  var settings = {
    dots: true,
    infinite: false,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // autoplay: autoScroll,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false
  };
  const size = useWindowDimensions();
  const [banners, setBanners] = React.useState([]);
  const classes = useStyles(theme);
  const value = "1";
  const search = "";
  const [toggleDrawerBottom, setToggleDrawerBottom] = React.useState(false);
  const [communityData, setCommunityData] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const app = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"
  const [noService, setNoService] = React.useState(false)
  const [property, setProperty] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const history = useHistory()
  const alert = useContext(AlertContext)
  const match = useMediaQuery('(min-width:602px)')
  const logo = JSON.parse(localStorage.getItem("accessType"))?.filter(
    (val) => val?.name === app
  );
  const [isViewerOpen, setIsViewerOpen] = React.useState({
    bool: false,
    data: 0
  });
  const [controlList, setControlList] = React.useState({
    quickAccessList: [],
    communityServices: []
  })
  const updateState = (index, close, data) => {
    if (close) {
      setIsViewerOpen({ ...isViewerOpen, bool: false })
    }
    else {
      setIsViewerOpen({ data: index, bool: !isViewerOpen?.bool, assets: data })
    }

  }
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const openAmenity = () => {
    setOpen(true)
  }
  const getBanners = () => {
    setLoading(true)
    let data = {
      type: value === "1" ? "Community" : "Services",
      tenantId: `${config.tenantid}`,
      company_id: logo?.map((val) => val?.company_id),
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/company-banner/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      setLoading(false)
      let bannerss = response.data.data.map((item) => item?.assets).flat();
      setBanners(bannerss);
    });
  };
  //get property
  const getProperty = () => {
    const data = {
    };
    NetworkCall(
      `${config.api_url}/resident/access_unit_community`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let resposepropertyId = response?.data?.data?.map(
          (val) => val?.property_id
        );
        let uniqArray = [...new Set(resposepropertyId)];
        setProperty(uniqArray)
        if (uniqArray?.length > 0) {
          getCommunityData(uniqArray, false, 0);
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }
  const getAppControlList = () => {
    const payload = {
      company_id: logo?.map((val) => { return val?.company_id })
    }
    NetworkCall(
      `${config.api_url}/company_application_control/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const activeControls = res?.data?.data?.filter((x) => { return (x?.app_build === config.app_x_build || x?.is_common) })?.map((val) => { return val?.title })
      console.log(activeControls,"kjhg")
      const quickList = frequentlyUsedData?.filter((x) => {
        return activeControls.includes(x?.title)
      })
      console.log(quickList,"kjhg")
      const communityServices = CommunityCorner?.filter((x) => {
        return activeControls.includes(x?.key_name)
      })
      setControlList({ quickAccessList: quickList, communityServices: communityServices })
    }).catch((error) => {
      console.log(error)
    });
  }
  const getCommunityData = (propertyid, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: propertyid,
      offset: offset,
      limit: 20,
    };
    NetworkCall(
      `${config.api_url}/community/fetch`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setCommunityData({
          list: response?.data?.data?.threads?.map((x) => {
            return {
              ...x,
              triggered_at: x?.created_at,
              description: [{
                Description: x?.description
              }],
              profile_url: x?.url
            }
          })
        })
      })
      .catch((err) => {
        console.log(err)
      });
  };
  const addLike = (val) => {
    let check = val?.liked_by?.some(
      (val) => val === localStorage.getItem("userProfileId")
    );
    const adddata = {
      query: ADD_LIKE,
      variables: {
        like: [
          {
            community_discussion: val.id,
            created_by: localStorage.getItem("userProfileId"),
            updated_by: localStorage.getItem("userProfileId"),
            is_active: true,
            client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
          },
        ],
      },
    };
    const deletedata = {
      query: UPDATELIKE,

      variables: {
        id: val.like_id,
        updatedData: {
          is_active: false,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      check ? deletedata : adddata,
      null,
      true,
      false
    )
      .then((response) => {
        getCommunityData(property)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  const homeCard = [
    {
      id: "1",
      img: "/images/homeCleanIMG.svg",
      title: "Home",
      sub: "Cleaning",
      link: "/service",
      filter: "Cleaning",
      noavailable: true,
      borderColor: "#8C20E5",
    },
    {
      id: "2",
      img: "/images/homeSanitization.svg",
      title: "Home",
      sub: "Sanitization",
      link: "/service",
      noavailable: true,
      filter: "Home Sanitization",
      borderColor: "#F7A72F",
    },
    {
      id: "3",
      img: "/images/bathrommCleanIMG.svg",
      noavailable: true,
      title: "Bathroom",
      sub: "Cleaning",
      link: "/service",
      filter: "Bathroom Cleaning",
      borderColor: "#50E6FF",
    },
    {
      id: "4",
      img: "/images/tempIMG.svg",
      title: "Home ",
      noavailable: true,
      sub: "Repair",
      link: "/service",
      filter: "Home Repair",
      borderColor: "#199BE2",
    },
    {
      id: "5",
      img: "/images/homePaintingIMG.svg",
      noavailable: true,
      title: "Home",
      sub: "Painting",
      link: "/service",
      filter: "Home Painting",
      borderColor: "#8C20E5",
    },
  ];

  const frequentlyUsedData = [
    {
      label_1: t("visitor"),
      label_2: t("Pass"),
      title: "Visitor Pass",
      icon_url: "/images/dashboardIcons/visitor_icon.svg",
      link: "/visitor_gate_pass",
      nextLine: t("pass"),
      show: true,
    },
    {
      label_1: t("Work"),
      label_2: t("permit"),
      title: "Worker Permit",
      icon_url: "/images/dashboardIcons/worker_icon.svg",
      link: "/worker_gate_pass",
      show: true,
    },
    {
      label_1: t("Amenity"),
      label_2: t("Booking"),
      title: "Amenity Booking",
      icon_url: "/images/dashboardIcons/amenities_icon.svg",
      link: openAmenity,
      type: "method",
      nextLine: t("Booking"),
      show: true,
    },
    {
      label_1: t("guest"),
      label_2: t("parking"),
      title: "Guest Parking",
      icon_url: "/images/dashboardIcons/parking_icon.svg",
      link: "/parking_pass",
      show: true,
    },
    {
      label_1: t("registered"),
      label_2: t("Worker"),
      title: "Registered Worker",
      icon_url: "/images/dashboardIcons/registered_icon.svg",
      link: "/domestic_help",
      show: true,
    },
    {
      label_1: t("Delivery &"),
      label_2: t("Parcels"),
      title: "Delivery & Parcels",
      icon_url: "/images/dashboardIcons/delivery_icon.svg",
      link: "/delivery",
      show: true,
    },
  ];
  const CommunityCorner = [
    // {
    //   name: "walk_ins",
    //   svg: <WalkIn />,
    //   link: "/service",
    // },
    // {
    //   name: "walk_ins",
    //   svg: <WalkIn />,
    //   link: "/service",
    //   noavailable: true,
    //   is_false:getAccess('is_walk_in_track') === false ? true : false
    // },
    {
      name: t("polls"),
      key_name:"Polls",
      svg: "images/pollsIcon.svg",
      link: "/service",
      noavailable: true,
      show: true,
      is_image: true
    },
    {
      name: t("survey"),
      key_name:"Survey",
      svg: "images/surveyIcon.svg",
      link: Routes?.surveyList,
      show:true,
      is_image: true
    },
    // {
    //   name: "notifications",
    //   svg: <Notifications />,
    //   link: "/notification",
    // },
    {
      name: t("Discussion Group"),
      key_name:"Discussion Group",
      svg: "images/discussionIcon.svg",
      link: "/community_discussion",
      show: true,
      is_image: true
    },
    {
      name: t("Organization"),
      key_name:"Committee Members",
      svg: "images/managementTeam.svg",
      // sub: "team",
      link: "/managment",
      is_image: true,
      show: true,
    },
    {
      name: t("Emergency Number"),
      key_name:"Emergency Number",
      svg: "images/emergencyIcon.svg",
      link: "/emergency_contact",
      is_image: true,
      show: true,
    },
  ];

  // essentials data
  const EssentialsData = [
    {
      id: "1",
      img: "/images/carRentalIMG.svg",
      title: "Car",
      sub: "Rental",
      link: "/service",
      filter: "Car Rental",
      noavailable: true,
      borderColor: "#388FE2",
    },
    {
      id: "2",
      img: "/images/postalResitentIMG.svg",
      title: "Postal",
      sub: " Assistance",
      link: "/service",
      filter: "Postal Assistance",
      noavailable: true,
      borderColor: "#007AD9",
    },
    {
      id: "3",
      img: "/images/mediaclResidentIMG.svg",
      title: "Medical",
      sub: "Supplies",
      link: "/service",
      filter: "Medical Supplies",
      noavailable: true,
      borderColor: "#D4943B",
    },
    {
      id: "4",
      img: "/images/pastIMG.svg",
      title: "Pest",
      sub: "Control",
      link: "/service",
      filter: "Pest Control",
      noavailable: true,
      borderColor: "#F09600",
    },
    {
      id: "5",
      img: "/images/packerIMG.svg",
      title: "Packer &",
      sub: "Movers",
      link: "/service",
      noavailable: true,
      filter: "Packer Movers",
      borderColor: "#FAD500",
    },
    {
      id: "6",
      img: "/images/legalIMG.svg",
      noavailable: true,
      title: "Legal",
      sub: "Assistance",
      link: "/service",
      filter: "Legal Assistance",
      borderColor: "#50E6FF",
    },
    {
      id: "7",
      img: "/images/carWashIMG.svg",
      title: "Car",
      sub: "Wash",
      link: "/service",
      noavailable: true,
      filter: "Car Wash",
      borderColor: "#388FE2",
    },
  ];

  //homeproduct data
  const Homeproduct = [
    {
      id: "1",
      img: "/images/sweetIMG.svg",
      title: "Sweets &",
      link: "/service",
      sub: "Savories",
      search: "Sweets Savories",
      noavailable: true,
      borderColor: "#F09600",
    },
    {
      id: "2",
      img: "/images/orgaincIMG.svg",
      title: "Organic",
      link: "/service",
      sub: "Products",
      search: "Organic Products",
      noavailable: true,
      borderColor: "#FAD500",
    },
    {
      id: "3",
      img: "/images/foodIMG.svg",
      title: "Food",
      noavailable: true,
      link: "/service",
      sub: "Supplies",
      search: "Food Supplies",
      borderColor: "#C966E9",
    },
  ];

  //township data tab 3
  const TownshipData = [
    {
      id: "1",
      icon: <YourTownshipIcon />,
      title: "Your",
      sub: "Township",
      link: "/service",
      filter: "Your Township",
      noavailable: true,
      borderColor: "#388FE2",
    },
    {
      id: "2",
      img: "/images/announcementIMG.svg",
      title: "Public",
      sub: "Announcements",
      link: "/service",
      filter: "Public Announcements",
      noavailable: true,
      borderColor: "#199BE2",
    },
    {
      id: "3",
      icon: <ContactMeIcon />,
      title: "Contact",
      sub: "Township",
      link: "/service",
      filter: "Contact Township",
      noavailable: true,
      borderColor: "#388FE2",
    },
    {
      id: "4",
      img: "/images/aminitiesAgree.svg",
      title: "Public",
      sub: "Amenities",
      link: "/service",
      filter: "Public Amenities ",
      noavailable: true,
      borderColor: "#C966E9",
    },
    {
      id: "5",
      icon: <PlaceOfInterestIcon />,
      title: "Places Of",
      sub: "Interest",
      link: "/service",
      noavailable: true,
      filter: "Places Of Interest",
      borderColor: "#388FE2",
    },
    // {
    //   id: "6",
    //   img: "/images/legalIMG.svg",
    //   noavailable: true,
    //   title: "Enroll",
    //   sub: "Residents",
    //   link: "/service",
    //   filter: "Enroll Residents",
    //   borderColor: "#50E6FF"

    // },
    {
      id: "7",
      icon: <CommitteeIcon />,
      title: "Township",
      sub: "Committee",
      link: "/service",
      noavailable: true,
      filter: "Township Committee",
      borderColor: "#388FE2",
    },
  ];

  //   const data = [{
  //     name: "Swimming Pool"
  //   },
  //   {
  //     name: "Table Tennis"
  //   },
  //   {
  //     name: "Badminton"
  //   },
  // ]
  React.useEffect(() => {
    handleTopBarState({
      t: t,
      note: true,
      i18n: i18n,
      // new: main?.unread,
    })
    getBanners();
    getProperty()
    getAppControlList()
    // eslint-disable-next-line
  }, [value]);

  const quickLinkClick = (item) => {
    if (item?.onClick) {
      item?.onClick()
    }
    if (item?.noavailable) {
      setNoService(true);
    } else if (item?.type === "method") {
      item?.link();
    }
    else if ([Routes?.amenities, Routes?.requestScreen, Routes?.units].includes(item?.link)) {
      history.push(item?.link + "?Id=" + item?.data);
    } else {
      history.push({
        pathname: item?.link,
        state: {
          dashboard: true,
          main: {
            agreement: item?.data,
          },
        },
      });
    }
  }

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TopNavBars 
       t={t}
       i18n={i18n}
        note={true}
        count="4"
        handleClicknote={() => history.push(Routes.notification)}
        document={() => history.push(Routes?.fileManager)}
      /> */}
      <Grid
        container
        className={classes.screenScrollEffect}
        style={{ height: size?.height - 138, overflow: "auto" }}
      >
        {loading ? <Grid item xs={12}> <AgreementSimmer /></Grid> :
          <Grid item xs={12} sx={{ padding: "12px" }}>
            <Grid container>

              {value === "2" && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography className={classes.titles}>
                        {t("HomeCare")}
                      </Typography>
                    </Grid>
                    {homeCard
                      ?.filter((movie) => {
                        if (search === "") {
                          return movie;
                        } else if (
                          movie?.title
                            ?.toLowerCase()
                            ?.includes(search?.toLowerCase())
                        ) {
                          return movie;
                        }
                      })
                      ?.map((val, index) => {
                        return (
                          <Grid key={index} item xs={4}>
                            <ServiceCard data={val} />
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Grid item xs={12} sx={{ margin: "12px 0px 0px 0px" }}>
                    <CarouselSliders autoScroll={true} data={banners} />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box height="4px" />
                      <Typography className={classes.titles}>
                        {t("Essentials")}
                      </Typography>
                    </Grid>
                    {EssentialsData?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })?.map((val, index) => {
                      return (
                        <Grid key={index} item xs={4}>
                          <ServiceCard data={val} noavailable />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box height="12px" />
                      <Typography className={classes.titles}>
                        {t("Homeproduct")}
                      </Typography>
                    </Grid>
                    {Homeproduct?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })?.map((val, index) => {
                      return (
                        <Grid key={index} item xs={4}>
                          <ServiceCard data={val} noavailable />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}

              {value === "1" && (
                <>
                  {banners?.length > 0 &&
                    <Grid item xs={12} sx={{ margin: "12px 0px 25px 0px" }}>
                      <CarouselSliders autoScroll={true} data={banners} />
                    </Grid>
                  }
                  <Grid item xs={12}>

                    {communityData?.list?.length > 0 &&
                      <Box mb={2}>
                        <Box display="flex" justifyContent={"space-between"} p={0} alignItems={"center"}>
                          <Typography className={classes.titles}>{t("Latest Discussions")}</Typography>
                          <Box onClick={() => history.push({ pathname: Routes?.communityDiscussion, state: { tabValue: "2" } })}>
                            <Typography className={classes.viewAllText}>{t("View All")}</Typography>
                          </Box>
                        </Box>
                        <Slider {...settings}>
                          {communityData?.list?.map((val) => {
                            return (
                              <Box mt={2}>
                                <NewCommunityCard t={t} data={val} isWidget={true} isAll={true} isCommunity={true} addLike={addLike} height={match ? "320px" : "280px"} updateState={updateState} />
                              </Box>
                            )
                          })}
                        </Slider>
                      </Box>
                    }
                    {controlList?.quickAccessList?.length > 0 &&
                      <>
                        <Typography className={classes.titles}>
                          {t("Quick Links")}
                        </Typography>
                        <Box sx={{ height: "12px" }} />
                        {/* <IconButtonCard openDrawer={() => { setToggleDrawerBottom(true) }} data={frequentlyUsedData} t={t} /> */}
                        <Box className={classes.quickLinkBox} mt={2}>
                          <Grid container spacing={2} py={"12px"}>
                            {
                              controlList?.quickAccessList?.map((data) => {
                                return (
                                  data?.show &&
                                  <Grid item xs={3}>
                                    <Stack alignItems={"center"} rowGap={1} onClick={() => quickLinkClick(data)}>
                                      <img src={data?.icon_url} alt="" className={classes.quickLinkImg} />
                                      <Stack rowGap={"4px"} alignItems={"center"}>
                                        <Typography className={classes.quickLinkLabel}>{data?.label_1}</Typography>
                                        <Typography className={classes.quickLinkLabel}>{data?.label_2}</Typography>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </Box>
                      </>
                    }
                    {controlList?.communityServices?.length > 0 &&
                      <>
                        <Box height={"16px"} />
                        <Box className={classes.frequentlyUsed}>
                          <Typography className={classes.titles}>
                            {t("CommunityCorner")}
                          </Typography>
                          <Box sx={{ marginTop: "13px" }}>
                            <UnitDetails openDrawer={() => { setToggleDrawerBottom(true) }} data={controlList?.communityServices} t={t} icon_bg={"#D9e6FE80"} />
                          </Box>
                        </Box>
                        <Box height={"20px"} />
                      </>
                    }
                    {/* <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.amenities}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box className={classes.amenitiesFixed}>
                            <div>
                              <Typography className={classes.amenitiesSmText}>
                                {t("Relax")} &#8226; {t("Play")} &#8226;{" "}
                                {t("Enjoy")}
                              </Typography>
                            </div>
                            <div>
                              <Typography className={classes.amenitiesTitle}>
                                {t("Theamenitiesforyou")}
                              </Typography>
                            </div>
                            <div>
                              <Typography className={classes.amenitiesViewAll} onClick={()=> setOpen(true)}>
                                {t("ViewAll")}{" "}
                                <ArrowRightOutline color="#298477" />
                              </Typography>
                            </div>
                          </Box>
                          <Box className={classes.amenitiesScroller}>
                              <AmenitiesScroller setOpen={setOpen} data={data} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid> */}
                    <DialogDrawer
                      open={toggleDrawerBottom}
                      onClose={() => setToggleDrawerBottom(false)}
                      onOpen={() => setToggleDrawerBottom(true)}
                      isnotTitle={true}
                      component={
                        <CommunityMemberModel
                          t={t}
                          onClose={() => setToggleDrawerBottom(false)}
                        />
                      }
                    />
                  </Grid>
                </>
              )}
              {value === "3" && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {TownshipData?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })?.map((val, index) => {
                      return (
                        <Grid key={index} item xs={4}>
                          <ServiceCard data={val} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
      </Grid>
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        // noService={true}
        component={<AmenityBookingNew closeServicePopup={() => setOpen(false)} />}
        onClose={() => setOpen(false)} />
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={noService}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        onClose={() => setNoService(false)}
      />
      <Hidden smUp>
        <Drawer
          open={isViewerOpen?.bool}
          onClose={() => updateState("", true)}
          className={classes.dialog}
        >
          <ImageViewer
            src={
              isViewerOpen?.assets?.map((x) => { return x?.url }) ?? []
            }
            currentIndex={isViewerOpen?.data}
            onClose={() => updateState("", true)}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          open={isViewerOpen?.bool}
          onClose={() => updateState("", true)}
          fullWidth
          className={classes.dialog}
          maxWidth="sm"
        >
          <ImageViewer
            src={
              isViewerOpen?.assets?.map((x) => { return x?.url }) ?? []
            }
            currentIndex={isViewerOpen?.data}
            onClose={() => updateState("", true)}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        </Dialog>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("service")(Service);