import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment/moment';
import React from 'react'
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { componentsUseStyles } from './styles';


const FeedbackListCard = (props) => {
  const { goView, t = () => false } = props;
  const classes = componentsUseStyles();
  const size = useWindowDimensions();
  return (
    <>
      <Box className={classes.listCardRoot} onClick={() => goView(props?.data)}>
        <Stack direction={"row"}>
          <img className={classes.feedbackIcon} src="/images/feedbackIcon.svg" alt="" />
          <Box width={size?.width >= 600 ? "474px" : (size?.width - 126)}>
            <Stack display={"flex"} width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography className={classes.title}>{props?.data?.title}</Typography>
              <Typography className={props?.data?.status === "Submitted" ? classes.statusSubmitted : classes.status}>{t(props?.data?.status)}</Typography>
            </Stack>
            <Box height={"8px"} />
            <Stack direction={"row"} alignItems={"center"} divider={<Box className={classes.dot} />}>
              <Typography className={classes.label}>{props?.data?.form_no}</Typography>
              {/* <Typography className={classes.label}>TKT-112345</Typography> */}
              <Box className={classes.dateContainer} >
                <img src="/images/icons8-calendar%20(5).svg" alt="" />
                <Typography className={classes.label}>
                &#x202a;{moment(props?.data?.created_at)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY")}&#x202c;
                </Typography>
              </Box>
            </Stack>
          </Box>

        </Stack>
      </Box>
    </>
  )
}

export default FeedbackListCard;