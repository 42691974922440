import { useApolloClient } from "@apollo/client";
import React from "react";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { GET_GATES, GET_PROOF } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, ValidateEmail, allowed_file_size, singleFileUpload } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { EntryType, InitialData, InitialWorkerDetails } from "./utils";
import moment from "moment";

export const AddWorkerPassContext = React.createContext();

const AddWorkerPassProvider = (props) => {
    const current_date = new Date();
    const { t=()=>false } = props;
    const size = useWindowDimensions();
    const classes = useStyles({ size });
    const client = useApolloClient();
    const history = useHistory();
    const searchURL = useLocation().search;
    const passID = new URLSearchParams(searchURL).get("passId");

    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);

    const [step, setStep] = React.useState(0);
    const [data, setData] = React.useState(InitialData(t, current_date));
    const [unitList, setUnitList] = React.useState([]);
    const [proofTypeList, setProofTypeList] = React.useState([]);
    const [isUnitListOpen, setIsUnitListOpen] = React.useState(false);
    const [startHourMeridiemAnchorEl, setStartHourMeridiemAnchorEl] = React.useState(null);
    const isStartHourMeridiemOpen = Boolean(startHourMeridiemAnchorEl);
    const startHourMeridiemID = isStartHourMeridiemOpen ? "start_hour_meridiem" : undefined;
    const [endHourMeridiemAnchorEl, setEndHourMeridiemAnchorEl] = React.useState(null);
    const isEndHourMeridiemOpen = Boolean(endHourMeridiemAnchorEl);
    const endHourMeridiemID = isEndHourMeridiemOpen ? "end_hour_meridiem" : undefined;
    const [isWorkerCategoryOpen, setIsWorkerCategoryOpen] = React.useState(false);
    const [isAddWorkerFormOpen, setIsAddWorkerFormOpen] = React.useState(false);
    const [workerFormData, setWorkerFormData] = React.useState(InitialWorkerDetails);
    const [isAddRegisteredWorkerOpen, setIsAddRegisteredWorkerOpen] = React.useState(false);
    const [registeredWorkerList, setRegisteredWorkerList] = React.useState([]);
    const [isOptionsLoading, setIsOptionsLoading] = React.useState(InitialWorkerDetails)
    const [gateList, setGateList] = React.useState([]);
    const [gateListOffset, setGateListOffset] = React.useState(0);
    const [isGateListOpen, setIsGateListOpen] = React.useState(false);
    const [isWorkerDetails, setIsWorkerDetails] = React.useState(false);
    const [viewWokerDetails, setViewWorkerDetails] = React.useState({})
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)

    const closeWorkerDetails = () => {
        setIsWorkerDetails(false);
        setViewWorkerDetails({});
    }

    const openWorkerDetails = (data) => {
        setViewWorkerDetails(data)
        setIsWorkerDetails(true);
    }

    React.useEffect(() => {
        if (passID) {
            getRequestDetails();
        }
        getUnitList();
        getProofData();
        // eslint-disable-next-line
    }, [passID]);

    const getRequestDetails = () => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
        const payload = { tenantId: `${config.tenantid}`, request_id: passID, };
        NetworkCall(
            `${config.api_url}/security/view_worker_request`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data?.[0]
            let temp_data = constructState(temp_response);
            setData(temp_data)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch(() => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        });
    };

    const constructState = (_) => {
        let tempState = {
            pass_details: {
                unit: {
                    id: _?.agreement_unit_id,
                    unit_id: _?.unit?.[0]?.id,
                    name: _?.unit?.[0]?.name,
                    property_id: _?.unit?.[0]?.property?.id,
                    property_name: _?.unit?.[0]?.property?.name,
                    unit_no: _?.unit?.[0]?.unit_no,
                    unit_assets: _?.unit?.[0]?.unit_assets,
                },
                entry_type: EntryType(t)?.filter(i => i?.value === _?.entry_type)?.[0],
                start_date: _?.request_from ? new Date(_?.request_from) : current_date,
                end_date: _?.request_from ? new Date(_?.request_to) : current_date,
                start_hour: _?.work_hour_start ? conver24HrTo12Hr(_?.work_hour_start)?.hours : "09",
                start_hour_meridiem: _?.work_hour_start ? conver24HrTo12Hr(_?.work_hour_start)?.meridiem : t("AM"),
                end_hour: _?.work_hour_end ? conver24HrTo12Hr(_?.work_hour_end)?.hours : "06",
                end_hour_meridiem: _?.work_hour_end ? conver24HrTo12Hr(_?.work_hour_end)?.meridiem : t("PM"),
                is_group: (_?.group_count > 1),
                group_count: _?.group_count?.toString() ?? "1",
                error: {
                    unit: "",
                    entry_type: "",
                    start_date: "",
                    end_date: "",
                    start_hour: "",
                    start_hour_meridiem: "",
                    end_hour: "",
                    end_hour_meridiem: "",
                    is_group: "",
                    group_count: "",
                },
            },
            worker_details: _?.security_visitors?.length > 0 ?
                _?.security_visitors?.map((_, index) => {
                    return {
                        id: _?.id,
                        index: index,
                        registered_worker_id: _?.is_registered_worker ? _?.domestic_helpers?.id : null,
                        is_deleted: false,
                        profile_image: _?.is_registered_worker ? _?.domestic_helpers?.helper_image :
                            (_?.visitor_image?.length > 0 ? _?.visitor_image : "/images/profile_image.png"),
                        name: _?.is_registered_worker ? _?.domestic_helpers?.name : _?.name,
                        mobile: _?.is_registered_worker ?
                            (_?.domestic_helpers?.mobile_no ?
                                { mobile: _?.domestic_helpers?.mobile_no, mobile_code: _?.domestic_helpers?.mobile_country_code } : "") :
                            (_?.mobile_no ? { mobile: _?.mobile_no, mobile_code: _?.mobile_country_code } : ""),
                        email_id: _?.is_registered_worker ? _?.domestic_helpers?.email_id : _?.email_id,
                        proof_type: _?.is_registered_worker ? _?.domestic_helpers?.identification : _?.identification,
                        proof_id: _?.is_registered_worker ? _?.domestic_helpers?.identification_no : _?.identification_no,
                        proof_assets: _?.worker_proof?.length > 0 ? _?.worker_proof : [],
                        registered_worker_ref_no: _?.is_registered_worker ? _?.domestic_helpers?.reference_id : "",
                        profession: _?.is_registered_worker ? _?.domestic_helpers?.profession_name : "",
                        error: {
                            profile_image: "",
                            name: "",
                            mobile: "",
                            email_id: "",
                            proof_type: "",
                            proof_id: "",
                            proof_assets: "",
                        },
                    }
                })
                : "",
            work_information: {
                work_description: _?.work_info?.[0]?.description ?? "",
                is_any_gate: _?.gate_id ? false : true,
                gate: _?.gate_id ? { label: _?.gate_name, value: _?.gate_id } : "",
                is_tenant_improvement: _?.work_info?.[0]?.qna?.tenantImprovement,
                is_facility_member_needed: _?.work_info?.[0]?.qna?.facilittiesStaff,
                is_noisy: _?.work_info?.[0]?.qna?.noisy,
                is_flame: _?.work_info?.[0]?.qna?.flames,
                is_vendor_safety: _?.work_info?.[0]?.qna?.vendorSafty,
                is_hazardous: _?.work_info?.[0]?.qna?.hazardous,
                error: {
                    work_description: "",
                    gate: "",
                }
            },
        }

        return tempState;
    }

    const getUnitList = () => {
        NetworkCall(
            `${config.api_url}/community/community_units`,
            NetWorkCallMethods.post,
            {}, null, true, false
        ).then((response) => {
            let _tempUnitList = response?.data?.data?.map((x) => x?.agreement_unit).flat();
            setUnitList(_tempUnitList);
            !passID && updateStateNew("pass_details", { _k: "unit", _v: _tempUnitList[0] })
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        });
    };

    const getProofData = () => {
        const params = {
            query: GET_PROOF,
            variables: {
                client: JSON.parse(localStorage.getItem(LocalStorageKeys?.client))?.id,
                proof_type: "Contact"
            },
            fetchPolicy: 'network-only',
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            params, null, true, false
        ).then((response) => {
            setProofTypeList(response.data.data.Identification_master);
        })
            .catch(() => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    };

    const updateStateNew = (k, v) => {
        let { _k, _v } = v;
        let tempState = {};

        switch (k) {
            case "pass_details":
                switch (_k) {
                    case "start_date":
                        tempState = {
                            ...data,
                            [k]: {
                                ...data?.[k], [_k]: _v,
                                error: {
                                    ...data?.[k]?.error,
                                    [_k]: _v > data?.[k]?.end_date ? t("Start Date should be lesser than End Date") : "",
                                }
                            }
                        }
                        break;
                    case "end_date":
                        tempState = {
                            ...data,
                            [k]: {
                                ...data?.[k], [_k]: _v,
                                error: {
                                    ...data?.[k]?.error,
                                    [_k]: "",
                                    start_date: data?.[k]?.start_date > _v ? t("Start Date should be lesser than End Date") : "",
                                },
                            }
                        }
                        break;

                    default:
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;
                }
                setData(tempState);
                break;
            case "work_information":
                switch (_k) {
                    case "is_any_gate":
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, gate: "", error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;

                    case "gate":
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, is_any_gate: false, error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;

                    default:
                        tempState = { ...data, [k]: { ...data?.[k], [_k]: _v, error: { ...data?.[k]?.error, [_k]: "" } } }
                        break;
                }
                setData(tempState);
                break;

            default:
                break;
        }
    }

    const handleEntryType = (value) => updateStateNew("pass_details", { _k: "entry_type", _v: value })

    const handleStartHourMeridiem = (value) => {
        value && updateStateNew("pass_details", { _k: "start_hour_meridiem", _v: value })
        setStartHourMeridiemAnchorEl(null)
    }

    const handleEndHourMeridiem = (value) => {
        value && updateStateNew("pass_details", { _k: "end_hour_meridiem", _v: value })
        setEndHourMeridiemAnchorEl(null)
    }

    const checkWorkerListEmpty = () => {
        let is_list_empty = true;
        let workerDetails = data?.worker_details;
        if (workerDetails?.length > 0) {
            for (let i = 0; i < workerDetails?.length; i++) {
                if (workerDetails?.[i]?.is_deleted === false) {
                    is_list_empty = false;
                }
            }
        }
        return is_list_empty;
    }

    const openWorkerForm = () => {
        setWorkerFormData({ ...InitialWorkerDetails, error: {} })
        setIsAddWorkerFormOpen(true)
        setIsWorkerCategoryOpen(false)
    }

    const closeWorkerForm = () => {
        setIsAddWorkerFormOpen(false)
        setWorkerFormData({ ...InitialWorkerDetails, error: {} })
    }

    const updateWorkerFormState = (key, value) => {
        setWorkerFormData({ ...workerFormData, [key]: value, error: { [key]: "" } });
    };

    const profileImageUpload = async (file) => {
        let uploaded_file = await singleFileUpload(file, { tenantId: `${config.tenantid}` }, alert, allowed_file_size)
        if (uploaded_file?.[0]?.url) {
            updateWorkerFormState("profile_image", uploaded_file?.[0]?.url);
        } else {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        }
    };

    const updateProofAssets = (assets) => {
        updateWorkerFormState("proof_assets", assets);
    }

    const workerFormValidate = () => {
        let isValid = true;
        let error = workerFormData.error;

        if (workerFormData?.name?.length === 0) { isValid = false; error.name = t("NameisRequired"); }
        if (workerFormData?.mobile?.length === 0) { isValid = false; error.mobile = t("MobileisRequired"); }
        if (workerFormData?.email_id?.length > 0) {
            if (ValidateEmail(workerFormData?.email_id) === false) {
                isValid = false;
                error.email_id = "Invalid Email ID";
            } else {
                error.email_id = ""
            }
        }
        setWorkerFormData({ ...workerFormData, error });
        return isValid;

    }

    const addWorkerFormData = () => {
        if (workerFormValidate()) {
            setData({ ...data, worker_details: [...data?.worker_details, { ...workerFormData, index: data?.worker_details?.length }] })
            setIsAddWorkerFormOpen(false);
            setWorkerFormData({ ...InitialWorkerDetails, error: {} })
        }
    }

    const deleteWorker = (worker) => {
        let temp_worker_list = [];
        let workerDetails = data?.worker_details
        for (let i = 0; i < workerDetails?.length; i++) {
            if (workerDetails?.[i]?.index === worker?.index) {
                temp_worker_list = [...temp_worker_list, { ...workerDetails?.[i], is_deleted: true }]
            } else {
                temp_worker_list = [...temp_worker_list, workerDetails?.[i]]
            }
        }
        setData({ ...data, worker_details: temp_worker_list })
    }

    const openRegisteredWorker = () => {
        setRegisteredWorkerList([])
        setIsAddRegisteredWorkerOpen(true)
        setIsWorkerCategoryOpen(false)
    }

    const closeRegisteredWorker = () => {
        setIsAddRegisteredWorkerOpen(false)
        setRegisteredWorkerList([])
    }

    const getOptions = async (search = "", array, type) => {
        setIsOptionsLoading(type);
        let offset, payload, temp_result, result = 0;
        let limit = 10;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }

        switch (type) {
            case "registered_worker":
                let temp_excluded_helpers = [
                    ...registeredWorkerList?.filter(i => (Boolean(i?.registered_worker_id) && !i?.is_deleted))?.map(({ registered_worker_id }) => registered_worker_id),
                    ...data?.worker_details?.filter(i => (Boolean(i?.registered_worker_id) && !i?.is_deleted))?.map(({ registered_worker_id }) => registered_worker_id),
                ];
                payload = {
                    property_id: [data?.pass_details?.unit?.property_id],
                    excluded_helpers: temp_excluded_helpers,
                    search, limit, offset
                }
                result = await NetworkCall(
                    `${config.api_url}/domestic_helper/helper_masters_list`,
                    NetWorkCallMethods.post, payload, null, true, false
                ).catch(() => {
                    alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something Went Wrong"),
                    });
                });
                temp_result = result?.data?.data?.helpers?.map((_) => {
                    return {
                        value: `${_?.helper_id}`,
                        label: `${_?.name} - ${_?.profession_name} - ${_?.reference_id}`,
                        ..._
                    }
                })
                setIsOptionsLoading(null);
                return {
                    options: [...temp_result],
                    hasMore: array?.length + temp_result?.length < result?.data?.data?.count,
                };

            default:
                return { options: [] };
        }
    };

    const addRegisteredWorkerList = (worker) => {
        let temp_worker = {
            ...InitialWorkerDetails,
            id: null,
            index: registeredWorkerList?.length,
            registered_worker_id: worker?.helper_id,
            is_deleted: false,
            profile_image: worker?.helper_image,
            name: worker?.name,
            mobile: { mobile: worker?.mobile_no, mobile_code: worker?.mobile_code },
            email_id: worker?.email_id,
            proof_type: worker?.kyc?.[0]?.identification_master?.type,
            proof_id: worker?.kyc?.[0]?.number,
            proof_assets: [],
            registered_worker_ref_no: worker?.reference_id,
            profession: worker?.profession_name,
        }
        setRegisteredWorkerList([...registeredWorkerList, temp_worker])
    }

    const deleteRegisteredWorker = (worker) => {
        let temp_worker_list = [];
        for (let i = 0; i < registeredWorkerList?.length; i++) {
            if (registeredWorkerList?.[i]?.index === worker?.index) {
                temp_worker_list = [...temp_worker_list, { ...registeredWorkerList?.[i], is_deleted: true }]
            } else {
                temp_worker_list = [...temp_worker_list, registeredWorkerList?.[i]]
            }
        }
        setRegisteredWorkerList(temp_worker_list)
    }

    const checkRegisteredWorkerListEmpty = () => {
        let is_list_empty = true;
        if (registeredWorkerList?.length > 0) {
            for (let i = 0; i < registeredWorkerList?.length; i++) {
                if (registeredWorkerList?.[i]?.is_deleted === false) {
                    is_list_empty = false;
                }
            }
        }
        return is_list_empty;
    }

    const addBulkRegisteredWorker = () => {
        let temp_registered_workers = registeredWorkerList?.filter(i => !i?.is_deleted)?.map((_, index) => {
            return {
                ..._,
                index: ((data?.worker_details?.length > 0) ? (data?.worker_details?.length + index) : index)
            }
        })
        setData({ ...data, worker_details: [...data?.worker_details, ...temp_registered_workers] })
        setIsAddRegisteredWorkerOpen(false)
        setRegisteredWorkerList([])
    }

    const openGateList = () => {
        getGateList(0, false)
        setIsGateListOpen(true)
    }

    const getGateList = (offset, fetchMore) => {
        client.query({
            query: GET_GATES(),
            fetchPolicy: 'network-only',
            variables: {
                property_id: data?.pass_details?.unit?.property_id,
                offset, limit: 10, search: ""
            }
        }).then((response) => {
            if (fetchMore) {
                setGateList((res) => [...res, ...response?.data?.access_gates_master]);
            } else {
                setGateList(response?.data?.access_gates_master);
            }
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
        });
    };

    const fetchMoreGateList = () => {
        const _gateListOffset = gateListOffset + 20;
        setGateListOffset(gateListOffset);
        getGateList(_gateListOffset, true);
    };

    const chooseGate = (v) => {
        updateStateNew("work_information", { _k: "gate", _v: v })
        setIsGateListOpen(false);
    }

    const passDetailsValidate = () => {
        let isValid = true;
        let error = data?.pass_details?.error;
        const passDetails = data?.pass_details;

        if (passDetails?.start_date > passDetails?.end_date) {
            isValid = false;
            error.start_date = t("Start Date should be lesser than End Date");
        }

        if (passDetails?.start_hour?.length === 0) {
            isValid = false;
            error.start_hour = t("Work Hour Start is Required");
        }

        if (passDetails?.end_hour?.length === 0) {
            isValid = false;
            error.end_hour = t("Work Hour End is Required");
        }

        if (passDetails?.is_group && passDetails?.group_count?.length === 0) {
            isValid = false;
            error.group_count = t("Group Count is Required");
        }

        setData({ ...data, pass_details: { ...passDetails, error }, });
        return isValid;
    };

    const workerDetailsValidate = () => {
        let isValid = true;

        if (checkWorkerListEmpty()) {
            isValid = false;
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Add Visitor Details"),
            });
        }

        return isValid;
    };

    const workerInformationValidate = () => {
        let isValid = true;
        let error = data?.work_information?.error;
        const workInformation = data?.work_information;

        if (workInformation?.work_description?.length === 0) {
            isValid = false;
            error.work_description = t("Work Description is Required");
        }

        setData({ ...data, workInformation: { ...workInformation, error }, });
        return isValid;
    };

    const handleNext = () => {
        switch (step) {
            case 0:
                workerInformationValidate() && setStep(1)
                break;
            case 1:
                passDetailsValidate() && setStep(2)
                break;
            case 2:
                workerDetailsValidate() && submitWorkerPass(constructPayload())
                break;
            default:
                break;
        }
    };

    const handlePrevious = () => {
        switch (step) {
            case 0:
                break;
            case 1:
                setStep(0)
                break;
            case 2:
                setStep(1)
                break;
            default:
                break;
        }
    };

    const conver12HrTo24Hr = (normalTime) => {
        const [time, meridiem] = normalTime.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') { hours = '00'; }
        if (meridiem === 'PM') { hours = parseInt(hours, 10) + 12; }
        return `${hours}:${minutes}:00`;
    }

    const conver24HrTo12Hr = (normalTime) => {
        const hour = parseInt(normalTime.split(':')?.[0]);
        let hours, meridiem = ""
        if (hour === 0) {
            hours = '12';
            meridiem = 'AM';
        } else if (hour > 0 && hour < 12) {
            hours = hour?.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            meridiem = 'AM';
        } else if (hour === 12) {
            hours = hour?.toString();
            meridiem = 'PM';
        } else {
            hours = (hour - 12)?.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            meridiem = 'PM';
        }
        return { hours, meridiem }
    }

    const constructPayload = () => {
        const passDetails = data?.pass_details;
        const workerDetails = data?.worker_details;
        const workerInformation = data?.work_information;
        const _temp_start_date = moment(passDetails?.start_date).format("yyyy-MM-DD");
        const _temp_end_date = moment(passDetails?.end_date).format("yyyy-MM-DD");
        const work_hour_start = conver12HrTo24Hr(`${passDetails?.start_hour}:00 ${passDetails?.start_hour_meridiem}`);
        const work_hour_end = conver12HrTo24Hr(`${passDetails?.end_hour}:00 ${passDetails?.end_hour_meridiem}`);
        const temp_start_date = new Date(passDetails?.start_date.setHours(passDetails?.start_hour, 0, 0));
        const temp_end_date = new Date(passDetails?.end_date.setHours(work_hour_end?.split(":")?.[0], 0, 0));
        const temp_stay_type = _temp_start_date === _temp_end_date ? "Short" : "Long";
        let temp_payload = {
            id: passID ?? undefined,
            tenantId: `${config.tenantid}`,
            pass_type: "WGR",
            pass_class: passDetails?.is_group ? "Group" : "Individual",
            stay_type: temp_stay_type,
            start_date: temp_start_date,
            end_date: temp_end_date,
            visitor_type: "Worker",
            user_profile_id: localStorage.getItem("userProfileId"),
            entry_type: passDetails?.entry_type?.value,
            property_id: passDetails?.unit?.property_id,
            unit_id: passDetails?.unit?.unit_id,
            agreement_unit_id: passDetails?.unit?.id,
            group_count: passDetails?.group_count,
            gate_no: workerInformation?.is_any_gate ? undefined : workerInformation?.gate?.value,
            work_hour_start: work_hour_start,
            work_hour_end: work_hour_end,
            work_info: [
                {
                    description: workerInformation?.work_description,
                    qna: {
                        tenantImprovement: workerInformation?.is_tenant_improvement,
                        facilittiesStaff: workerInformation?.is_facility_member_needed,
                        individualAccess: false,
                        noisy: workerInformation?.is_noisy,
                        hazardous: workerInformation?.is_hazardous,
                        flames: workerInformation?.is_flame,
                        vendorSafty: workerInformation?.is_vendor_safety,
                    }
                },
            ],
            deleted_profiles: workerDetails?.filter((_) => (Boolean(_?.id) && _?.is_deleted)).map((__) => {
                return __?.id
            }),
            reg_worker_profiles: workerDetails?.filter((_) => (!Boolean(_?.id) && Boolean(_?.registered_worker_id) && !_?.is_deleted)).map((__) => {
                return __?.registered_worker_id
            }),
            new_worker_profiles: workerDetails?.filter((_) => (!Boolean(_?.id) && !Boolean(_?.registered_worker_id) && !_?.is_deleted)).map((__) => {
                return {
                    name: __?.name,
                    mobile_no: __?.mobile?.mobile,
                    mobile_country_code: __?.mobile?.mobile_code,
                    email_id: __?.email_id,
                    visitor_image: __?.profile_image,
                    id_type: __?.proof_type?.value,
                    identification_no: __?.proof_id,
                    worker_proof: __?.proof_assets,
                }
            }),
        };
        return temp_payload;
    };

    const submitWorkerPass = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/security/upsert_security_worker`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Pass Successfully Updated"),
            });
            setIsDisableBtn(false)
            history.goBack(-1)
        }).catch(() => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
            setIsDisableBtn(false)
        });
    }

    return (
        <AddWorkerPassContext.Provider
            value={{
                t,
                classes,
                size,
                step,
                setStep,
                handlePrevious,
                handleNext,
                data,
                unitList,
                isUnitListOpen,
                setIsUnitListOpen,
                updateStateNew,
                current_date,
                handleEntryType,
                startHourMeridiemAnchorEl,
                setStartHourMeridiemAnchorEl,
                isStartHourMeridiemOpen,
                startHourMeridiemID,
                handleStartHourMeridiem,
                endHourMeridiemAnchorEl,
                setEndHourMeridiemAnchorEl,
                isEndHourMeridiemOpen,
                endHourMeridiemID,
                handleEndHourMeridiem,
                proofTypeList,
                checkWorkerListEmpty,
                isWorkerCategoryOpen,
                setIsWorkerCategoryOpen,
                openWorkerForm,
                closeWorkerForm,
                isAddWorkerFormOpen,
                setIsAddWorkerFormOpen,
                workerFormData,
                updateWorkerFormState,
                profileImageUpload,
                updateProofAssets,
                addWorkerFormData,
                deleteWorker,
                openRegisteredWorker,
                isAddRegisteredWorkerOpen,
                setIsAddRegisteredWorkerOpen,
                getOptions,
                isOptionsLoading,
                registeredWorkerList,
                addRegisteredWorkerList,
                deleteRegisteredWorker,
                checkRegisteredWorkerListEmpty,
                closeRegisteredWorker,
                addBulkRegisteredWorker,
                gateList,
                openGateList,
                fetchMoreGateList,
                isGateListOpen,
                setIsGateListOpen,
                chooseGate,
                openWorkerDetails,
                isWorkerDetails,
                setIsWorkerDetails,
                viewWokerDetails,
                closeWorkerDetails,
                isDisableBtn
            }}
        >
            {props?.children}
        </AddWorkerPassContext.Provider>
    )
}

export default withNamespaces("createPass")(AddWorkerPassProvider);