import { KeyboardArrowRight } from "@mui/icons-material";
import {
    Box,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import { format } from "date-fns";
import * as React from "react";
import Slider from "react-slick";
import { AssetIcon } from "../../assets/assetIcon";
import { CalendarIcon } from "../../assets/calendarIcon";
import { ChargesIcon } from "../../assets/chargesIcon";
import { LocationIcon } from "../../assets/locationIcon";
import { ProjectedBOM } from "../../assets/projectedBom";
import { RequestIcon } from "../../assets/requestIcon";
import { VisitIcon } from "../../assets/visitIcon";
import { generateImageUrl, img_size } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import MicIcon from '@mui/icons-material/Mic';

const MaintenanceNewCard = (props) => {
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    };
    const { t = () => false } = props
    const language = localStorage.getItem("i18nextLng")
    const classes = useStyles();
    const size = useWindowDimensions();
    const switchRequestStatusColor = (val) => {
        switch (val) {
            case "Open": {
                return "#78B1FE"
            }
            case "Closed": {
                return "#5AC782"
            }
            case "Cancelled": {
                return "#FF4B4B"
            }
            case "Assigned": {
                return "#ffd700"
            }
            case "Reopened": {
                return "#FF9340"
            }
            case "Upfront Charges": {
                return "#6DAFB3"
            }
            case "Projected BOM": {
                return "#896DB3"
            }
            case "Upcoming Visit Approval": {
                return "#B3776D"
            }
            default: {
                return "#78B1FE"
            }
        }
    }
    return (
        <>
            <Slider {...settings}>
                {props?.action?.length > 0 && props?.action?.map((val) => {
                    return (
                        <>
                            <Box display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: switchRequestStatusColor(val?.name), borderRadius: "12px 13px 0px 0px", padding: "4px 12px" }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    {val?.name === "Upfront Charges" ? <ChargesIcon /> : val?.name === "Projected BOM" ? <ProjectedBOM /> : <VisitIcon />}
                                    <Typography className={classes.actionText}>{`${t(val?.name)} ${t(val?.status)}`}</Typography>
                                </Stack>
                                <KeyboardArrowRight sx={{ color: "#FFFFFF" }} />
                            </Box>
                        </>
                    )
                })}
            </Slider>
            <Box p={1.5} onClick={props?.onClick}>
                <Grid container spacing={2} sx={{ cursor: "pointer" }}>
                    <Grid item xs={3} sm={1.8}>
                        <Box className={classes.imageBox} style={{ position: "relative",marginLeft:"2px" }}>
                            {props?.image_type === "mp4"
                                ? (props?.image
                                    ? <video muted autoPlay={false}
                                        style={{
                                            height: "75px",
                                            width: "75px",
                                            borderRadius: "6px",
                                            marginTop: "12px",
                                        }}>
                                        <source src={props?.image + "#t=0.001"} type="video/mp4" />
                                    </video>
                                    : <Box height={"75px"} width={"75px"} marginTop={"12px"} />)
                                : props?.image_type === "mpeg"
                                    ? (props?.image
                                        ? <Box
                                            style={{
                                                height: "75px",
                                                width: "75px",
                                                borderRadius: "6px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                            <MicIcon fontSize="large" htmlColor="#5078e1" />
                                        </Box>
                                        : <Box height={"75px"} width={"75px"} marginTop={"12px"} />)
                                    : <img
                                        src={generateImageUrl(props?.image, img_size.small_square) ?? "/images/requestPlaceholder.svg"}
                                        alt="Repair"
                                        class="center"
                                        className={props?.image !== null ? classes.image : classes.placeholderImg}
                                    />}
                        </Box>
                    </Grid>
                    <Grid item xs={9} sm={9.8}>
                        <Stack direction={"column"} flexGrow={1}>
                            <Box>
                                <Typography className={classes.header} sx={{ maxWidth: size?.width - 155 }} noWrap>
                                    {props.header}
                                </Typography>
                            </Box>
                            {props?.asset_name !== null && props?.asset_name?.length > 0 &&
                                <Box mt={0.5}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <AssetIcon />
                                        <Typography className={classes.subHeader}>{props?.asset_name}</Typography>
                                    </Stack>
                                </Box>
                            }
                              {(props?.asset_name===null||props?.asset_name==="")&&props?.unit_type !== null && props?.unit_type?.length > 0 &&
                                <Box mt={0.5}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <AssetIcon />
                                        <Typography className={classes.subHeader}>{props?.unit_type}</Typography>
                                    </Stack>
                                </Box>
                            }
                            <Box mt={0.5}>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                    <LocationIcon />
                                    <Typography className={classes.address}>{`${props?.unit_name} , ${props?.property_name}`}</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>

                </Grid>
                <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={3} sm={1.8}>
                        <Box>
                            <Typography
                                variant="subtitle2"
                                noWrap
                                className={classes.progress}
                                sx={{ backgroundColor: switchRequestStatusColor(props?.request_status) }}
                            >
                                {t(props?.request_status)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={9} sm={9.8}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <CalendarIcon />
                                <Typography className={classes.subdetails}>&#x202a;{format(new Date(props?.date), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")}&#x202c;</Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <RequestIcon />
                                <Typography className={classes.subdetails}>{props?.type}</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MaintenanceNewCard;
